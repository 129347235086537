import React from 'react';
import './collection.css';
import { AutoSizer, Grid  } from 'react-virtualized';
import d1Img from '../../images/w-collection1.webp';
import loadingGif from '../../images/loading-gif.gif';
import d2Img from '../../images/w-collection2.webp';
import DataStorageFile from '../../utils/data-storage.js';
import WalletManager from "../../services/wallet-manager";
import CollectionService from "./services/collection-services";
import { withTranslation } from 'react-i18next';
import {Link    } from "react-router-dom";

const dataStorage = new DataStorageFile();

class CollectionComponent extends React.Component {


  constructor() {
    super();
    this.collectionService = new CollectionService()
    this.state = {
      jsonCollection : null,
      loadedCollection : String,
      loading : false,
    };
  }
  
  componentDidMount() {
    // this.loadCollection()
    this.loadImagesCollection()
    if (this.props.onRef) {
      this.props.onRef(this);
      // this.loadCollection()

    }
  }
  
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  async componentDidUpdate(prevProps) {
    if ((this.props.collectionId !== this.state.loadedCollection) || this.props.updateCollection) {
      this.setState({updateCollection: false})
      this.setState({loadedCollection: this.props.collectionId})
      await this.loadCollection()

    }
  }

  async loadImagesCollection() {
    if(!(this.state.watchImages)){
      const jsonArray = await this.collectionService.getImages()
      this.setState({watchImages: jsonArray})

    }
  }
  
  async loadCollection() {
    let res = null;

    try {
      if (this.props.collectionId) {
        this.setState({loading: true})
        res = await this.collectionService.getByName(this.props.collectionId)

      } else if (dataStorage.getData('walletId')) {
        this.setState({loading: true})
        res = await this.collectionService.getByWallet(dataStorage.getData('walletId'))
      }
      else {
        return null
      }
      if (res) {

        this.setState({loading: false})
        this.setState({"jsonCollection": res.result});
        this.setState({"pending": res.pending});
      }

    }
      catch(e){
      console.error(e)
      }
    }


  getBackgroundImageUrl(brand) {
    if(this.state.watchImages) {
      return this.state.watchImages[brand] || "/images/collection/c_rolex.png";
    } else {
      return "/images/collection/c_rolex.png"
    }
  }

  connectWallet = async () => {
    let walletConnector = new WalletManager()
    let walletId = await walletConnector.connectWallet()
    if(walletId){
      this.setState({connected: true,
        updateCollection: !this.state.updateCollection})
      await this.loadCollection()

    }
  }

  render() {

    const { t } = this.props;

    const pendingDiv =
      this.state.pending > 0 ? (
          // Conditional content for when `pending` is greater than 0
          <div>
            {/* Replace "A message for when pending items are present" with your actual message */}
            <div className="pending-message"> {t('pending.nft')} </div>
          </div>
      ) : null

    let itemElements = []
    if(this.state.jsonCollection) {
       itemElements = this.state.jsonCollection.map((item) => {
        const imageUrl = this.getBackgroundImageUrl(item.brand);
        const backgroundImageStyle = {
          backgroundImage: `url('${imageUrl}')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          overflow: 'hidden'
        };

        return (
            <div className='item-watchcard-container'>
              <div className='item-watchcard' style={backgroundImageStyle}></div>
              <div className='item-watchcard-description'>
                <p>{item.brand}</p>
                <p>{item.model}</p>
                <p>{item.year}</p>
              </div>
            </div>
        );
      });
    }
    const walletId = dataStorage.getData("walletId") || "";
    let shortenedWalletId = dataStorage.getData("walletShortened") || ""
    if(dataStorage.getData("walletName")){
      shortenedWalletId = dataStorage.getData("walletName")+'\'s collection';
    }
    else if(dataStorage.getData("walletShortened")){
      shortenedWalletId = dataStorage.getData("walletShortened")+`s collection`;
    }

    return (
      <div className="collection">
        <div className="sidebar">
          {shortenedWalletId && (
              <>
                <h1>{shortenedWalletId}</h1>
                <div className="side-item">{t('avg.value')}
                  <div className="side-item2">
                  <span>Not available</span>
                </div>
                  </div>
                <div className="side-item">{t('locations')}                   <div className="side-item2">
                  <span>Not available</span>
                </div></div>
                <div className="side-item">{t('ultra.rares')}                   <div className="side-item2">
                  <span>0</span>
                </div></div>
              </>
          )}
          <br></br>
          <div className="side-buttons">
            {(!walletId) && (
                <button onClick={this.connectWallet} className="collection-buttons">
              {t('connect.wallet')} &nbsp;
            </button>
          )}
          <Link to="/client/mint">
            <button className="collection-buttons">
              {t('new.watch')}
            </button>
          </Link>

            {(!this.props.collectionId && shortenedWalletId) && (
                <>
                  <Link to="/collection/change-name">
                    <button className="collection-buttons">
                      {t('change.name')}
                    </button>
                  </Link>
                  <Link to="/client/mint">
                    <button className="collection-buttons">
                      {t('transfer')}
                    </button>
                  </Link>
                </>
                )}
          </div>
        </div>



        {
          this.state.loading ?
              (
                  <div className="loading-container">
                    <img src={loadingGif} className="loading-gif"  alt=""/>
                  </div>
              )
              :
              (
                  // NOT CONNECTED
                  this.state.jsonCollection === null ?
                      <div className="collection-main">
                        <div className="collection-not-connected">
                          <div className="big-message">
                            {t('connect.text1')}
                          </div>
                          <div className="collection-second-message">
                            {t('connect.text2')}
                          </div>
                          <div className="not-logged-collection-images">
                            <img className="animated-collection" src={d1Img} alt="" />
                            <img className="animated-collection2" src={d2Img} alt="" />
                          </div>
                          <div className="glow-wrap">
                            <i className="glow"></i>
                          </div>
                        </div>
                      </div>
                      :
                      // EMPTY WALLET
                      itemElements.length === 0 ?
                          (
                          <div className="collection-main">
                               {pendingDiv}
                                <div className="collection-not-connected">
                                  <div className="big-message">
                                    {t('wallet.empty.text1')}
                                  </div>
                                  <div className="collection-second-message">
                                    {t('wallet.empty.text2')}
                                  </div>
                                  <div className="not-logged-collection-images">
                                    <img className="animated-collection" src={d1Img} alt=""/>
                                    <img className="animated-collection2" src={d2Img} alt=""/>
                                  </div>
                                  <div className="glow-wrap">
                                    <i className="glow"></i>
                                  </div>
                                </div>
                              </div>
                          ) :
                          (
                              // ITEMS IN THE WALLET
                              <div className="collection-main">
                                {pendingDiv}
                                <AutoSizer className="react-virtualized-auto-sizer">
                                  {({height, width}) => {
                                    const itemWidth = 260;
                                    const columnCount = Math.floor(width / itemWidth);
                                    const columnWidth = itemWidth;
                                    const rowCount = Math.ceil(itemElements.length / columnCount);
                                    const rowHeight = 320;

                                    const cellRenderer = ({columnIndex, key, rowIndex, style}) => {
                                      const index = rowIndex * columnCount + columnIndex;
                                      if (index >= itemElements.length) {
                                        return null;
                                      }

                                      return (
                                          <div key={key} style={{ ...style, padding: 30}}>
                                            {itemElements[index]}
                                          </div>
                                      );
                                    };

                                    return (
                                        <div className="react-virtualized-div-fix">
                                          <Grid
                                              cellRenderer={cellRenderer}
                                              columnCount={columnCount}
                                              columnWidth={columnWidth}
                                              height={height}
                                              rowCount={rowCount}
                                              rowHeight={rowHeight}
                                              width={width}
                                          />
                                        </div>
                                    );
                                  }}
                                </AutoSizer>
                              </div>
                          )
              )
        }

      </div>
              );
            }
          }


export default withTranslation()(CollectionComponent);