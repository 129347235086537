import axios from 'axios';
export default class MintClientService{
   
    constructor() { }

    async start(body){
        try {
            const url = process.env.REACT_APP_API_URL+'/client/mint-to';
            const data = {
                'address': body.wallet,
                'year' : body.year,
                'brand': body.brand,
                'model' : body.model,
                'metadata' : body.metadata,
                'serialNumber' : body.serialNumber,
                'pricePaid' : body.pricePaid
            };
            const headers = {
                Authorization: localStorage.getItem('token')
            };
            const response = await axios.post(url, data, {headers});
            return response
        } catch (error) {
            console.log(error)
            return error
        }
    }

    async mintToClient(body){
        try {
            const url = process.env.REACT_APP_API_URL+'/v1/mint-storage-only';
            const headers = {
            };
            const response = await axios.post(url, body, {headers});
            console.log(response)
            return response
        } catch (error) {
            console.log(error)
            return error
        }
    }




}