import axios from 'axios';

export default class ApiIntegrationServices{
   

    async create(){
        console.log('CREATE TOKEN')
        try {
            const url = process.env.REACT_APP_API_URL+'/create-integration-token'; // URL da API
            const headers = {
                Authorization: localStorage.getItem('token')
            };

            const response = await axios.post(url, {},{headers});
            console.log(response)
            return response
        } catch (error) {
            console.log(error)
            return error
        }
    }

    async list(){
        try {
            const url = process.env.REACT_APP_API_URL+'/tokens'; // URL da API
            const headers = {
                Authorization: localStorage.getItem('token')
            };
            return await axios.get(url, {headers})
        } catch (error) {
            console.log(error)
            return error
        }
    }





}