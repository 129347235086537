
class DataStorage {
    constructor() {
      if (!DataStorage.instance) {
        this._data = {};
        DataStorage.instance = this;
      }
      return DataStorage.instance;
    }
  
    setData(key, value) {
      this._data[key] = value;
    }
  
    getData(key) {
      return this._data[key];
    }
  
    getAllData() {
      return this._data;
    }
  }
  

export default DataStorage;
