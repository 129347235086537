import axios from 'axios';
export default class MintService{
   
    constructor() { }

    async start(body){
        try {
            const url = process.env.REACT_APP_API_URL+'/v1/mint-to';
            const data = {
                'address': body.wallet,
                'year' : body.year,
                'brand': body.brand,
                'model' : body.model,
                'metadata' : body.metadata,
                'serialNumber' : body.serialNumber
            };
            const headers = {
                Authorization: localStorage.getItem('token')
            };
            const response = await axios.post(url, data, {headers});
            return response
        } catch (error) {
            console.log(error)
            return error
        }
    }




}