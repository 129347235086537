import React, { useState } from 'react';
import './mintpartnercomponent.css';
import MyAutosuggest from "../../suggest-component";
import MintService from './services/mint-service.js';
import {Navigate, redirect} from "react-router-dom";
import { ClipLoader } from 'react-spinners';
;

class MintPartnerComponent extends React.Component {
  
  
  constructor() {
    super();
    this.state = {
      brand: '',
        loading: false,
        response: null,
        showForm: true
    };
  }
  
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

  }


  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  handleSubmit = (event) => {

    event.preventDefault();
    this.setState({ loading: true });

    const mintService = new MintService()

    mintService.start(this.state).then(r => {
          if (r.status === 401) {
            localStorage.removeItem('token')
            this.forceUpdate()
          }
        else if(r.status === 200) {
            this.setState({ loading: false, response: r, showForm: false });
        }
    }
    )}

  handleBrandChange = (value) => {
    this.setState({ brand: value });
  };

  handleBack = () => {
    this.setState({ showForm: true, response: null });
  }
  handleModelChange = (value) => {
    this.setState({ model: value });
  };

  render() {
    if (!localStorage.getItem('token')) {
      return <Navigate to="/login" replace={true}/>
    }

    return (
        <div className="mint">
          <div className='title'>Mint</div>
          <div className='mint-wrapper'>

            {this.state.loading ?
                <ClipLoader/> :
                this.state.response && !this.state.showForm ?
                    <div>
                      <div>NFT minted successfully.</div>
                      <button onClick={this.handleBack}>Back</button>
                    </div> :
                    <div className="section-form">
                      <form onSubmit={this.handleSubmit} className="form">


                        <div>
                          <input
                              type="text"
                              placeholder="Wallet"
                              id="wallet"
                              name="wallet"
                              required
                              value={this.state.wallet}
                              onChange={(event) => this.setState({wallet: event.target.value})}
                              className="form-input"
                          />
                        </div>


                        <div>
                          <MyAutosuggest selectedData="brand"
                                         placeholder="Brand"
                                         onChange={this.handleBrandChange}
                                         value={this.state.brand}
                          />
                        </div>

                        <div>
                          <MyAutosuggest selectedData="model"
                                         placeholder="Model"
                                         onChange={this.handleModelChange}
                                         value={this.state.model}
                          />
                        </div>


                        <div>
                          <input
                              type="text"
                              placeholder="Year"
                              id="year"
                              name="year"
                              required
                              value={this.state.year}
                              onChange={(event) => this.setState({year: event.target.value})}
                              className="form-input"
                          />
                        </div>
                        <div>
                          <input
                              type="text"
                              placeholder="Serial Number"
                              id="serialNumber"
                              name="serialNumber"
                              value={this.state.serialNumber}
                              onChange={(event) => this.setState({serialNumber: event.target.value})}
                              className="form-input"
                          />
                        </div>
                        <div>
                          <input
                              type="text"
                              placeholder="Meta data"
                              id="metadata"
                              name="metadata"
                              value={this.state.metadata}
                              onChange={(event) => this.setState({metadata: event.target.value})}
                              className="form-input"
                          />
                        </div>

                        <button type="submit">Submit</button>
                      </form>
                    </div>
            }

          </div>
        </div>
    );
  }
  }
  
  
  export default MintPartnerComponent;
  