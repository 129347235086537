import React, { useState } from 'react';
import { myABI } from '../../utils/helper.js'
import './partnershipcomponent.css';


class PartnershipComponent extends React.Component {
  
  
  constructor() {
    super();
    this.state = {
    };
  }
  
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }
  
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }
  
  

  render() {    

    
    return(
      <div className="about">
      <div className='title'>About</div>
      <div className='wrapper'>
      <div className='box'>
    
        </div>
      </div>
      </div>
      );
    }
  }
  
  
  export default PartnershipComponent;
  