import axios from 'axios';

export default class ContentService{
   
    constructor() { }

    async home(){
        try {
            const url = process.env.REACT_APP_API_URL+'/home/content'
            const response = await axios.get(url, {});
            return response
        } catch (error) {
            return error
        }
    }






}