import React from 'react';
import './App.css';
import './header.css';
import './structure.css';
import WalletManager from './services/wallet-manager.js';
import CollectionServices from './services/collection-services.js';
import PageOne from "./components/home-page/page-one-component";
import CollectionPage from "./components/collection-page/collection-component";
import ChangeNameCollectionPage from "./components/collection-page/change-name-collection-component";
import AboutPage from "./components/about-page/about-component";
import PartnershipPage from "./components/partnership-page/partnership-component";
import MintPartnerPage from "./components/partner-area/mint-partner-page/mint-partner-component";
import MintClientPage from './components/mint-client-page/mint-client-component'
import LoginPage from "./components/login-page/login-component";
import PrivacyPolicyPage from "./components/privacy-policy/privacy-policy";
import SkinPage from "./components/skins-page/skin-home-component";
import SignupPage from "./components/register-page/register-component";
import DashboardPage from "./components/partner-area/dashboard-page/dashboard-component";
import IntegrationAPIPage from "./components/partner-area/api-integration-page/api-integration-home-component";
import { withTranslation } from 'react-i18next';
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent  } from "firebase/analytics";

import logoImg from './images/logo-of-black.png';

import Bottom from "./components/bottom-component";
import {
    Routes,
    Route,
    Link,
    HashRouter,
    useParams
} from "react-router-dom";

import DataStorageFile from './utils/data-storage.js';
import MyAutosuggest from "./components/suggest-component";
const dataStorage = new DataStorageFile();


const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY ,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASURE_ID
};

// Initialize Firebase
const fire = initializeApp(firebaseConfig);
const analytics = getAnalytics(fire);
const collectionServices = new CollectionServices()
logEvent(analytics, 'page_view', { page_path: '/home' });

class App extends React.Component {

    constructor() {
        super()

        dataStorage.setData("masterContract",process.env.REACT_APP_MASTER_CONTRACT)
        dataStorage.setData("HTTP_PROVIDER",process.env.REACT_APP_WEB3PROVIDER)

        this.state = {
            loading: false,
            connected: false,
            updateCollection: false,
            searchBarText: ""
        };
    }

    // Connect to user wallet
    connectWallet = async () => {
        try {
            let walletConnector = new WalletManager()
            let walletId = await walletConnector.initWallet()
            if(walletId){
                dataStorage.setData("walletId", walletId)
                const shortenedWalletId = `${walletId.slice(0, 6)}...${walletId.slice(-6)}`
                dataStorage.setData("walletShortened", shortenedWalletId)
                const possibleName = await this.collectionService.findCollectionNameByWallet(this.props.collectionId || dataStorage.getData('walletId'))
                if(possibleName.result){
                    dataStorage.setData("walletName", possibleName.result)
                }
                console.log('connected wallet id  : ' + dataStorage.getData('walletId'))
                this.setState({connected: true,
                    updateCollection: !this.state.updateCollection})
            }

        } catch (e) {
            console.error("Error connecting wallet:", e);
        }
    }

    handleSearch = async (text) => {
        if(text) {
            let response = await collectionServices.searchByNameOrWallet(text);
            if (response.status === 200) {
                dataStorage.setData("headerSearchResponse", response.data)
            }
        }
    }

    onSuggestionSelected = (_, { suggestion }) => {
        // Chame o onSuggestionSelected passado via prop, se existir
        if (typeof this.props.onSuggestionSelected === 'function') {
            this.props.onSuggestionSelected(_, { suggestion });
        }
    };


    render() {


        const { t } = this.props;
        return (

            <div className="body">

        <div className="main-container">
                    <div className="container-one">
                        <HashRouter >

                            <nav>
                                <header className="header">
                                    <Link to="/">
                                        <img src={logoImg} className="logo-image" alt=""/>
                                    </Link>
                                    <div className="menu-title">Watchdex</div>


                                    {/*<div className="menu">*/}
                                    <div className="header-title"><Link to="/">Home</Link></div>
                                    {/* <button className="menu-item" onClick={this.myCollection.bind(this)}> */}
                                    <div className="header-title"><Link to="/collection"> {t('menu.collection')}</Link>
                                    </div>
                                    {/*<div className="header-title"><Link to="/skins">{t('menu.skins')}</Link></div>*/}
                                    <div className="header-title"><Link to="/about">{t('menu.about')}</Link></div>
                                    {/*<div className="header-title"><Link to="/dashboard">Partner</Link></div>*/}


                                    <div id="searchHeader">
                                        <MyAutosuggest selectedData="searchHeader"
                                                       placeholder={t('search')}
                                                       onChange={this.handleSearch}
                                                       value={this.state.searchBarText}
                                                       onSuggestionSelected={this.onSuggestionSelected}
                                        />

                                    </div>
                                    <div className="header-title"><p> BETA </p></div>

                                    <div className="header-title"><p>{t('attention')} </p></div>

                                    {/*<div className="header-title"><Link to="#"*/}
                                    {/*                                      onClick={this.connectWallet}>    {this.state.connected ?*/}
                                    {/*    <span style={{color: 'green'}}> Connected ● </span> :*/}
                                    {/*    <span>Connect Wallet </span>}</Link>*/}
                                    {/*</div>*/}
                                    {/*</div>*/}

                                </header>

                            </nav>


                            <div className="components-body">
                            <Routes>
                                <Route path="/" element={<PageOne></PageOne>}/>
                                <Route path="collection/name/:collectionId" element={<CollectionPageWrapper/>} />
                                <Route path="collection" element={<CollectionPage />} />
                                <Route path="about" element={<AboutPage></AboutPage>}/>
                                <Route path="partnership" element={<PartnershipPage></PartnershipPage>}/>
                                <Route path="signup" element={<SignupPage></SignupPage>}/>
                                <Route path="mint" element={<MintPartnerPage></MintPartnerPage>}/>
                                <Route path="client/mint" element={<MintClientPage></MintClientPage>}/>
                                <Route path="login" element={<LoginPage></LoginPage>}/>
                                <Route path="dashboard" element={<DashboardPage></DashboardPage>}/>
                                <Route path="skins" element={<SkinPage></SkinPage>}/>
                                <Route path="integration" element={<IntegrationAPIPage></IntegrationAPIPage>}/>
                                <Route path="privacy-policy" element={<PrivacyPolicyPage></PrivacyPolicyPage>}/>
                                <Route path="collection/change-name" element={<ChangeNameCollectionPage></ChangeNameCollectionPage>}/>
                            </Routes>
                        </div>

                        </HashRouter>
                    </div>
                    <Bottom></Bottom>
                </div>
            </div>


        );
    }
}
function CollectionPageWrapper() {
    let { collectionId } = useParams();
    return <CollectionPage collectionId={collectionId} />;
}

export default withTranslation()(App);