import React from 'react';
import './dashboardcomponent.css';
import UserServices from '../../login-page/services/login-service.js';
import {Link, Navigate} from "react-router-dom";


class DashboardComponent extends React.Component {
    constructor() {
        super();
        this.state = {
            balanceHistory: [],
            // initialized: false
        };
    }

    async componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        // this.setState({initialized: true});
        await this.initServices();
    }



    initServices = async () => {
        const userService = new UserServices();
        try {
            let res = await userService.balanceRecord();
            switch (res.response.status) {
                case 401:
                    localStorage.removeItem("token");
                    this.setState({balanceHistory: []});
                    break;
                case 200:
                    console.log('success balance history');
                    this.setState({balanceHistory: res.data});
                    break;
                default:
                    console.log('unexpected status code');
            }
        } catch (error) {
            console.log('error in init services dashboard component');
        }
    };

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }


    render() {
        if (!localStorage.getItem('token')) {
            return <Navigate to="/login" replace={true}/>
        }

        return (
            <div className="dashboard">
                <div className='title'>Welcome back!</div>
                <div className='dashboard-wrapper'>
                    <div className='dashboard-box'>
                        <div className="dash-buttons"><Link to="/mint">Mint</Link></div>
                        <div className="dash-buttons"><Link to="/integration">API Integration</Link></div>

                        <br></br>


                        <table>
                            <thead>
                            <tr>
                                <th>Available balance:</th>
                                <th>{localStorage.getItem('balance')}</th>
                                <th></th>

                            </tr>
                            <tr>
                                <th> ㅤ </th>
                                <th></th>
                                <th></th>
                            </tr>
                            <tr>
                                <th>History</th>
                                <th></th>
                                <th></th>
                            </tr>

                            <tr>
                                <th>Amount</th>
                                <th>Date</th>
                                <th>Operation</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.balanceHistory.map((entry, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{entry.amount}</td>
                                        <td>{new Date(entry.date).toLocaleString()}</td>
                                        <td>{entry.operation}</td>
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        );
    }
}


export default DashboardComponent;
  