import React from 'react';
import '../../App.css';
import '../../index.css';
import sampleVideo from "../../images/video6.webm";
import leftWatch from "../../images/home-w-left.webp";
import { withTranslation } from 'react-i18next';
import ContentServices from './services/content-services.js';
import TwitterTimeline from '../twitter-component'
import blockImg from "../../images/block.webp";

class PageOne extends React.Component {
    constructor() {
        super();
        this.loadHomeContent()
        this.state = {};
    }

    async loadHomeContent(){
        this.contentServices = new ContentServices()
        const res = await this.contentServices.home()
        if(res.status === 200) {
            this.setState({"content": res.data});
        } else {
            return ""
        }
    }


    render() {
        const topBrands = this.state.content && Array.isArray(this.state.content["top-week-brands"])
            ? this.state.content["top-week-brands"]
            : [];

        const topCountries = this.state.content && Array.isArray(this.state.content["top-week-country"])
            ? this.state.content["top-week-country"]
            : [];

        const recentlyAdded = this.state.content && Array.isArray(this.state.content["recently-added"])
            ? this.state.content["recently-added"]
            : [];

        const { t } = this.props;
        return (
            <div className="page-one">
                <div className="page-one-video-content">
                    <section-one-component className="page-one-component">
                        <div className="font-face-gm">{ t('home.title1' )}</div>
                    </section-one-component>
                    <video className="background-video" autoPlay loop muted>
                        <source src={sampleVideo} type="video/mp4"/>
                    </video>
                </div>
                <div className="home-section-two-box">
                <div className="home-section-two">
                    <div className="container">
                        <div className="box">
                            <div className="home-box-title">{t('top.brands.month')}</div>
                            {topBrands.map((brand, index) => (
                                <div key={index} className="home-box-itens">{index+1 +". "+brand}</div>
                            ))}
                        </div>
                        <div className="box">
                            <div className="home-box-title">{t('top.countries.month')}</div>
                            {topCountries.map((country, index) => (
                                <div key={index} className="home-box-itens">{index+1 +". "+country}</div>
                            ))}
                        </div>
                    </div>
                </div>
                    <div className="home-section-three">
                        <div className="container">
                            <div className="box">
                                <div className="home-box-title-container">
                                    <div className="home-box-title">{t('recent.additions')}</div>
                                </div>
                                <div className="home-box-recent-additions-title-container">
                                    <span className="home-box-recent-additions-title">Brand</span>
                                    <span className="home-box-recent-additions-title">Model</span>
                                    <span className="home-box-recent-additions-title">Year</span>
                                    <span className="home-box-recent-additions-title">Price</span>
                                </div>
                                <div className="home-box-itens">
                                    {recentlyAdded.map((item, index) => (
                                        <div key={index} className="home-box-detail-container">
                                            <span className="home-box-recent-detail">{item.brand}</span>
                                            <span className="home-box-recent-detail">{item.model}</span>
                                            <span className="home-box-recent-detail">{item.year}</span>
                                            <span className="home-box-recent-detail">{item.pricePaid}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>


                            <div className="box">
                                <br></br>
                            </div>
                        </div>
                        <div className="home-section-four">
                            <img src={leftWatch} className="left-w-img" alt=""/>
                            <div className="container">
                                <div className="box">
                                    <div className="home-box-title">3 Month Watch Index</div>
                                    <div className="home-box-itens">⬇ -1.3%</div>
                                    <br></br>
                                    <br></br>
                                    <div className="home-box-title">1 Year Watch Index</div>
                                    <div className="home-box-itens">⬇ -12.5%</div>


                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                {/*<TwitterTimeline />*/}
            </div>

        );
    }
}

export default withTranslation()(PageOne);