import React from 'react';
import './about.css';
import '../../App.css';
import '../../index.css';
import d1Img from '../../images/diagram001.png';
import ethImg from '../../images/ethereum.png';
import arweaveLogo from '../../images/1663945896503.png';
import { withTranslation } from 'react-i18next';
import why from "../../images/why.png";
import how from "../../images/how.png";
import blockImg from "../../images/block.webp";
import sampleVideo from "../../images/video6.webm";


class AboutComponent extends React.Component {

    constructor() {
        super();
        this.state = {};
    }

 
    render() {

        const { t } = this.props;

        return (
            <div className="about-wrapper">
                <div className="video-content">
                    <section-one-component className="about-one-component">
                        <div className="font-face-gm">{ t('home.title1' )}</div>
                        <div className="font-face-regular">{t('home.title2')}</div>
                    </section-one-component>
                    <video className="background-video" autoPlay loop muted>
                        <source src={sampleVideo} type="video/mp4"/>
                    </video>
                </div>

                <div className="body1" id="body1">

                    <div className="section-mission">
                        <h1> {t('mission')} </h1>
                    </div>

                    <div className="container-mission">
                        <div className="block block-1">
                            {t("store.every.watch")}
                        </div>

                        <div className="block block-2">
                            {t("bridge.to.blockchain")}
                        </div>
                    </div>


                    <div className="container-small-white-why">
                        <img src={why} style={{width: '200px', height: 'auto'}} alt={t('why.img.alt')}/>

                    </div>

                    <div className="container-why">
                        <div className="container-why-block-1">
                            <h1>{t('purchase.history')}<br></br>
                                {t('fraud.prevention')}
                            </h1>
                        </div>

                        <div className="container-why-block-2">
                            <h1>
                                {t('digital.showroom')}<br></br>
                                {t('global.recipe.warranty')}
                            </h1>
                        </div>
                    </div>

                    <div className="container-small-white-how">
                        <img src={how} style={{width: '170px', height: 'auto'}} alt={t('how.img.alt')}/>
                    </div>

                    <div className="container-how-black">
                        <div className="container-how-black-block-1">
                            <h1>{t('decentralized')} <br></br>
                                {t('zero.web2')}<br></br>
                                {t('non.fungible.token')}<br></br>
                            </h1>
                        </div>

                        <div className="container-how-black-block-2">
                            <img src={blockImg} style={{width: '100%', height: 'auto'}} className="bg-image" alt={t('block.img.alt')}/>
                        </div>
                    </div>

                    <div className="container-how-white">
                        <div className="container-how-white-block-1">
                            <h1>{t('ethereum')}
                                <br></br>
                                {t('most.reliable.network')}
                            </h1>
                        </div>

                        <div className="container-how-white-block-2">
                            <h1>{t('no.database')} <br></br>{t('storage.uses.ipfs')} </h1>
                        </div>
                    </div>

                </div>
                <div className="about">
                        <div className='about-box'>
                            <div className="about-general-message">

                                <div className="about-box-1">
                                    <t1>
                                        {t("about1")}
                                    </t1>

                                    {/* <div className="button-box">*/}
                                    {/*<button className="button-about-page" >Download full paper here</button>*/}
                                    {/*</div>*/}

                                </div>
                                <div className="about-box-2">
                                    <div>{t("about2")}</div>
                                    <div className="images-container">
                                        <img src={ethImg} alt=""/>
                                        <img src={arweaveLogo} alt=""/>
                                    </div>
                                </div>


                                <div className="about-box-3">
                                    <div className="about-box-3-wrapper">
                                        {t("about3")}

                                    </div>
                                    <img src={d1Img} style={{width: '400px', height: '400pxs'}} alt=""/>


                                </div>

                                {/*<div className="about-box-4">*/}
                                {/*    <h1>Roadmap</h1><br></br>*/}

                                {/*    <div className="roadmap">*/}
                                {/*        [Alpha - On going] - Goal: Validating contract, integration and storage formats<br></br>*/}
                                {/*        <br></br>*/}
                                {/*        Next steps:*/}
                                {/*        <br></br>*/}
                                {/*        [Beta] Build a gateway to provide APIs to interact with the blockchain. Also, validate the integration with partners <br></br>*/}
                                {/*        <br></br>*/}
                                {/*        [V1] Final contract and overall architecture. Showroom. Adjustments in integrations based on partners feedback. <br></br>*/}
                                {/*        <br></br>*/}
                                {/*        [V2] New swap smart contract - enable side watch validation -<br></br>*/}

                                {/*    </div>*/}


                                {/*</div>*/}
                                <div className="about-box-2">
                                    <h1>{t("partnership")}</h1>

                                    {t("seeking.partnership")}

                                </div>


                            </div>
                        </div>


                </div>
            </div>
        );
    }
}


export default withTranslation()(AboutComponent);
  