import axios from 'axios';

export default class CollectionService{
   
    constructor() { }

    async searchByNameOrWallet(text){
        try {
            const url = process.env.REACT_APP_API_URL+'/collection/search/'+text; // URL da API
            const response = await axios.get(url, {});
            return response
        } catch (error) {
            return error
        }
    }






}