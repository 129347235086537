import axios from 'axios';
import DataStorageFile from '../../../utils/data-storage.js';
const dataStorage = new DataStorageFile();
export default class CollectionService{
    constructor() {
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    }
    async getByWallet(walletId){
        try {
            const url = process.env.REACT_APP_API_URL+'/collection/'+walletId;
            const response = await axios.get(url);
            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error);
        }

    }

    async getByName(name){
        try {
            const url = process.env.REACT_APP_API_URL+'/collection/name/'+name;
            const response = await axios.get(url);
            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error);
        }
    }

    async getImages(){
        try {
            const url = process.env.REACT_APP_API_URL+'/imgs/watches';
            const response = await axios.get(url);
            console.log(response)
            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error);
        }

    }

    async checkName(name) {
        try {
            const url = process.env.REACT_APP_API_URL+'/collection/check-name/'+name;
            const response = await axios.get(url);
            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error);
        }
    }

    async findCollectionNameByWallet(wallet) {
        try {
            const url = process.env.REACT_APP_API_URL+'/collection/find-name-by-wallet/'+wallet;
            const response = await axios.get(url);
            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error);
        }
    }





}