import React from 'react';
import './mint-client-component.css';
import InputSuggest from "../input-suggest";
import MintService from './services/mint-client-service.js';
import AuthService from '../../services/auth-services.js';
import {Link} from "react-router-dom";
import loadingGif from '../../images/loading-gif.gif';
import { withTranslation } from 'react-i18next';
import DataStorageFile from '../../utils/data-storage.js';
import {myABI} from "../../utils/helper";
import {Web3} from "web3";
import WalletManager from "../../services/wallet-manager";
import ContractInteractor from "../../services/contract-interactor";
const dataStorage = new DataStorageFile();



class MintClientComponent extends React.Component {


  constructor() {
    super();
      this.contractInteractor = new ContractInteractor()
      this.authService = new AuthService();
      this.state = {
          brand: '',
          loading: false,
          response: true,
          showForm: true,
          connectionFailure: false
    };
      if(process.env.REACT_APP_DEV_MODE == 1){
          this.state.year = 1993
          this.state.model = "Testerrrrrrrrrr"
          this.state.brand = "Rolexvar"
      }


  }


  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }


  }


  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.processNFT()
  }

    async uploadInfo() {
        const mintService = new MintService()
        let r = await mintService.mintToClient({
                brand: this.state.brand,
                model: this.state.model,
                year: this.state.year,
                serialNumber: this.state.serialNumber,
                metadata: this.state.metadata,
                pricePaid: this.state.pricePaid,
                wallet: dataStorage.getData("walletId")
            }
        )
        if(r.status === 200) {
            return r.data.result
        }
    }

    async preCheck(){
        if (!dataStorage.getData("walletId")) {
            return false
        }
        this.setState({ loading: true });
        try {
            const res = await this.authService.checkAvalability();
            if (!res.data.status) {
                this.setState({ loading: false, response: false, showForm: true });
                return false
            } else {
                this.setState({response: true})
            }
        } catch(e){
            this.setState({ loading: false, response: false, showForm: true });
            return false
        }
        return true
    }

    async processNFT() {
        if(!await this.preCheck()){
            return
        }
        try {
            let uploadResponse = await this.uploadInfo()
            // let uploadResponse = "659c963e3f4e55c59f56a230"
            // console.log('mocked mongId')
            if (uploadResponse) {
                let transactionObject = await this.contractInteractor.payForMinting(uploadResponse)
                if(!transactionObject){
                    return this.setState({ loading: false, response: false, showForm: true });
                }
                const web3metamask = new Web3(window.ethereum);
                web3metamask.eth.sendTransaction(transactionObject)
                    .on('transactionHash', hash => {
                        this.setState({ loading: false, response: true, showForm: false });
                        console.log('Transaction hash:', hash);
                    })
                    .on('receipt', receipt => {
                        this.setState({ loading: false, response: true, showForm: false });
                        console.log('Transaction receipt:', receipt);
                    })
                    .on('error', error => {
                        console.error('Error sending transaction:', error);
                    });
            }
        }
        catch (e)
            {

            }
    }
  handleBrandChange = (value) => {
    this.setState({ brand: value });
  };

  handleBack = () => {
    this.setState({ showForm: true, response: null });
  }
  handleModelChange = (value) => {
    this.setState({ model: value });
  };

  connectWallet = async () => {
        let walletConnector = new WalletManager()
        let walletId = await walletConnector.connectWallet()
        if(walletId){
            this.setState({connected: true,
                updateCollection: !this.state.updateCollection})

        }
    }

    handlePriceChange = (event) => {
        const numericValue = event.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
        const formattedValue = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(numericValue / 100); // Formata como moeda

        this.setState({
            pricePaid: numericValue,
            formattedPrice: numericValue ? formattedValue : ''
        });
    }

  render() {
    const { t } = this.props;
    const walletId = dataStorage.getData("walletId") || "";

    return (
        <div className="mint-client">
            <div className="sidebar">
                {(!walletId) && (
                    <button onClick={this.connectWallet} className="collection-buttons">
                        {t('connect.wallet')} &nbsp;
                    </button>
                )}
                <Link to="/collection">
                    <button className="collection-buttons">
                        {t('back.to.collection')}
                    </button>
                </Link>
                <div className='mint-explanation-banner'>

                    <p style={{ whiteSpace: 'pre-wrap' }}>
                        {t('client.mint.explanation')}
                    </p>
                </div>
            </div>


          <div className='mint-client-wrapper'>

            {this.state.loading ?
                <img src={loadingGif} className="loading-gif" alt=""/>
                :
                this.state.response && !this.state.showForm ?
                    <div className="text-success-mint">
                      <div>{ t('mint.success')} </div>
                      {/*<button onClick={this.handleBack}>Back</button>*/}
                    </div> :
                    <div className="mint-form">
                        {!this.state.response ? <div className='fail-mint'>{t('mint.fail')} </div> : ''}

                        <form onSubmit={this.handleSubmit} className="form">
                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="Wallet"
                                    id="wallet"
                                    name="wallet"
                                    required
                                    value={dataStorage.getData("walletId")}
                                    onChange={(event) => this.setState({wallet: event.target.value})}
                                    className="form-input"
                                    readOnly
                                />
                                <span> {t('form.wallet')}</span>
                            </div>

                            <div className="form-group">
                                <InputSuggest selectedData="brand"
                                              placeholder="Brand"
                                              onChange={this.handleBrandChange}
                                              value={this.state.brand}
                                />
                                <span> {t('form.brand')}</span>
                            </div>

                            <div className="form-group">
                                <InputSuggest selectedData="model"
                                              placeholder="Model"
                                              onChange={this.handleModelChange}
                                              value={this.state.model}
                                />
                                <span> {t('form.model')}</span>
                            </div>
                            <div className="form-group">
                                <input
                                    type="number"
                                    placeholder="Year"
                                    id="year"
                                    name="year"
                                    required
                                    value={this.state.year}
                                    onChange={(event) => {
                                        // Permitir apenas números e limitar o comprimento a 4 dígitos
                                        const yearValue = event.target.value;
                                        if (yearValue.length <= 4 && /^\d*$/.test(yearValue)) {
                                            this.setState({year: yearValue});
                                        }
                                    }}
                                    pattern="\d{4}"
                                    className="form-input"
                                    min="1900"
                                    max="2099"
                                />
                                <span> {t('form.year')}</span>
                            </div>

                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="Price Paid"
                                    id="price"
                                    name="price"
                                    value={this.state.formattedPrice}
                                    onChange={this.handlePriceChange}
                                    className="form-input"
                                />
                                <span>{t('form.price')}</span>
                            </div>

                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="Serial Number"
                                    id="serialNumber"
                                    name="serialNumber"
                                    value={this.state.serialNumber}
                                    onChange={(event) => this.setState({serialNumber: event.target.value})}
                                    className="form-input"
                                />
                                <span> {t('form.serial.number')}</span>
                            </div>

                            <div className="form-group">
                                <input
                                    type="text"
                                    placeholder="Meta data"
                                    id="metadata"
                                    name="metadata"
                                    value={this.state.metadata}
                                    onChange={(event) => this.setState({metadata: event.target.value})}
                                    className="form-input"
                                />
                                <span> {t('form.metadata')}</span>
                            </div>

                            <button type="submit">{t('form.create.nft.submit')}</button>
                        </form>
                    </div>
            }

          </div>
        </div>
    );
  }
}


export default withTranslation()(MintClientComponent);