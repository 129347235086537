import axios from 'axios';

export default class AuthServices{
   
    constructor() { }

    async check(){
        try {
            const url = process.env.REACT_APP_API_URL+'/check-token'; // URL da API
            const headers = {
                Authorization: localStorage.getItem('token')
            };
            const response = await axios.get(url, {headers});
            return response
        } catch (error) {
            return error
        }
    }

    async list(){
        try {
            const url = process.env.REACT_APP_API_URL+'/tokens'; // URL da API
            const headers = {
                Authorization: localStorage.getItem('token')
            };
            return await axios.get(url, {headers})
        } catch (error) {
            console.log(error)
            return error
        }
    }

    async checkAvalability(){
        try {
            const url = process.env.REACT_APP_API_URL+'/check-availability';
            return await axios.get(url, {})
        } catch (error) {
            console.error(error)
            return error
        }
    }







}