import React, { useEffect } from 'react';

const TwitterTimeline = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <a
            className="twitter-timeline"
            data-theme="dark"
            data-height="600"
            href="https://twitter.com/thewatchdex?ref_src=twsrc%5Etfw"
        >
            Tweets by thewatchdex
        </a>
    );
};

export default TwitterTimeline;
