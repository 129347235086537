import React, { useState } from 'react';
import './registercomponent.css';
import RegisterService from './services/register-service.js';

class RegisterComponent extends React.Component {
  
  
  constructor() {
    super();
    this.state = {
    };
  }
  
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }
  
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const registerService = new RegisterService()
    registerService.start(this.state).then(r => console.log("") )
  }

  render() {
    return(
      <div className="register">
      <div className='title'>Sign Up</div>
      <div className='wrapper'>
      <div className='box'>
        <div className="section-form">
            <form onSubmit={this.handleSubmit} className="form">

              <div>
                <input
                    type="text"
                    placeholder="Name"
                    id="name"
                    name="name"
                    value={this.state.name}
                    onChange={(event) => this.setState({name: event.target.value})}
                    className="form-input"
                />
              </div>

              <div>
                <input
                    type="text"
                    placeholder="E-mail"
                    id="email"
                    name="email"
                    required
                    value={this.state.email}
                    onChange={(event) => this.setState({email: event.target.value})}
                    className="form-input"
                />
              </div>


              <div>
                <input
                    type="text"
                    placeholder="Password"
                    id="password"
                    name="password"
                    value={this.state.password}
                    onChange={(event) => this.setState({password: event.target.value})}
                    className="form-input"
                />
              </div>

              <button type="submit">Submit</button>
            </form>
      </div>
      </div>
      </div>
      </div>
      );
    }
  }
  
  
  export default RegisterComponent;
  