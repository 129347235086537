import Autosuggest from 'react-autosuggest';
import React, { useState } from 'react';
import WatchHelper from '../utils/watch-helper';
import DataStorageFile from '../utils/data-storage.js';
import { Navigate } from "react-router-dom"

const dataStorage = new DataStorageFile();


const watchModels = WatchHelper.getWatchModels();
const brandModels = WatchHelper.getWatchBrands();


class MyAutosuggest extends React.Component {

  constructor() {
    
    super();

    this.state = {
      value: '',
      suggestions: [],
      data: watchModels,
      redirectToReferrer: false
    };
  }

  

escapeRegexCharacters = str => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
  
  getSuggestions = value => {
    const escapedValue = this.escapeRegexCharacters(value.trim());
    
    if (escapedValue === '') {
      return [];
    }
  
    const regex = new RegExp('^' + escapedValue, 'i');
    if(this.props.selectedData === 'brand'){
      return brandModels.filter(language => regex.test(language.name));

    } 
    else if(this.props.selectedData === 'model'){
      return watchModels.filter(language => regex.test(language.name));
    }
    else if(this.props.selectedData === 'searchHeader'){
      return dataStorage.getData('headerSearchResponse').filter(language => regex.test(language.name));
    }

  }
  
  getSuggestionValue = suggestion => {
    return suggestion.name;
  }
  
  renderSuggestion = suggestion => {
    return (
      <div>
        <div className="searchName"> {suggestion.name} </div>
        <div className="searchWallet"> {suggestion.walletShort} </div>
      </div>

  );
  }

  onChange = (_, { newValue }) => {
    this.setState({
      value: newValue
    });

    dataStorage.setData(this.props.selectedData, newValue)

    // Chame onChange apenas com o newValue
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(newValue);
    }
  };
  
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };

  onSuggestionSelected = (_, { suggestion }) => {
    console.log(suggestion)
    this.setState({walletSelected:suggestion.name})
    this.setState({redirectToReferrer:true})

  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const redirectToReferrer = this.state.redirectToReferrer;
    if (redirectToReferrer) {
      this.setState({redirectToReferrer: false})
      const str = '/collection/name/'+this.state.walletSelected
      console.log(str)
      return <Navigate to={str} replace={true} />

    }
  
    const { id, placeholder } = this.props;
    const { value, suggestions } = this.state;
    const inputProps = {
      placeholder,
      value,
      onChange: this.onChange
    };

    
    return (
      <Autosuggest 
        id={id}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={inputProps} 
      />
    );
  }
}


export default MyAutosuggest;
