import axios from 'axios';
import DataStorageFile from '../../../utils/data-storage.js';
const dataStorage = new DataStorageFile();
export default class SkinService{
    constructor() {
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    }
    async list(filter){
        try {
            const url = process.env.REACT_APP_API_URL+'/skin/market/list?filter='+filter
            const response = await axios.get(url);
            if (response.status === 200) {
                return response.data
            }
        } catch (error) {
            console.error(error);
        }

    }

}