import React, { useState } from 'react';
import './privacy-policy.css';
import {Navigate, redirect} from 'react-router-dom';



class PrivacyPolicy extends React.Component {


  constructor() {
    super();
  }
  


  render() {

    return(
      <div className="privacy-policy">
      <div className='title'>Login</div>
      <div className='policy-wrapper'>

      </div>
      </div>
      );
    }
  }
  
  
  export default PrivacyPolicy;
  