import axios from 'axios';

export default class LoginService{
    constructor() {
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    }
    async start(body){
        try {
            const url = process.env.REACT_APP_API_URL+'/signup'; // URL da API
            const data = {
                'email': body.email,
                'name': body.name,
                'password' : body.password
            };
            const response = await axios.post(url, data);
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }

    }

}