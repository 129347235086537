import React from 'react';
import './skin-home-component.css';
import { AutoSizer, Grid  } from 'react-virtualized';
import d1Img from '../../images/w-collection1.webp';
import loadingGif from '../../images/loading-gif.gif';
import d2Img from '../../images/w-collection2.webp';
import DataStorageFile from '../../utils/data-storage.js';
import WalletManager from "../../services/wallet-manager";
import SkinService from "./services/skin-services";
import { withTranslation } from 'react-i18next';
import {Link    } from "react-router-dom";

const dataStorage = new DataStorageFile();

class SkinHomeComponent extends React.Component {


  constructor() {
    super();
      this.state = {
          skinList : null,
          loadedCollection : String,
          loading : false,
      };
    this.skinService = new SkinService();

  }
  
  componentDidMount() {
      this.loadMarket()
    if (this.props.onRef) {
      this.props.onRef(this);
      // this.loadCollection()

    }
  }

  async loadMarket(){
      const list = await this.skinService.list('recent')
      this.setState({'skinList' : list.list})
  }
  
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  async componentDidUpdate(prevProps) {
    console.log(this.state.skinList)
  }


  connectWallet = async () => {
    let walletConnector = new WalletManager()
    let walletId = await walletConnector.connectWallet()
    if(walletId){
      this.setState({connected: true,
        updateCollection: !this.state.updateCollection})
      await this.loadCollection()

    }
  }

    handleBuyClick = () => {
        console.log('Botão BUY clicado!');

    }

  render() {

    const { t } = this.props;

    let itemElements = []
    if(this.state.skinList) {
       itemElements = this.state.skinList.map((item) => {
        const backgroundImageStyle = {
          backgroundImage: `url('${item.uri}')`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          overflow: 'hidden'
        };

        return (
            <div className='item'>
                <div className='skin-watchcard' style={backgroundImageStyle}></div>
                <div className='skin-watchcard-description'>
                    <p>{item.brand}</p>
                    <p>{item.description}</p>
                    <br></br>

                    <p>{item.price} ETH </p>
                </div>
                <button className="buy-button" onClick={this.handleBuyClick}>BUY</button>
            </div>
        );
       });
    }
      const walletId = dataStorage.getData("walletId") || "";
      let shortenedWalletId = ""
      if (this.state.collectionName) {
          shortenedWalletId = this.state.collectionName + '\'s collection';
      } else if (this.props.collectionId) {
          shortenedWalletId = this.props.collectionId+'\'s collection';
    }
    else if(walletId) {
      shortenedWalletId = `${walletId.slice(0, 6)}...${walletId.slice(-6)}'s collection`;
    }

    return (
      <div className="main">
        <div className="sidebar">

          <br></br>

          <div className="side-buttons">

          </div>
        </div>



        {
          this.state.loading ?
              (
                  <div className="loading-container">
                    <img src={loadingGif} className="loading-gif"  alt=""/>
                  </div>
              )
              :
              (
                  // EMPTY MARKET
                  this.state.skinList === null ?
                      <div className="collection-main">
                        <div className="collection-not-connected">
                          <div className="big-message">
                            {t('skins.empty1')}
                          </div>
                          <div className="collection-second-message">
                            {t('skins.empty2')}
                          </div>
                          <div className="not-logged-collection-images">
                            <img className="animated-collection" src={d1Img} alt="" />
                            <img className="animated-collection2" src={d2Img} alt="" />
                          </div>
                          <div className="glow-wrap">
                            <i className="glow"></i>
                          </div>
                        </div>
                      </div>
                      :
                      // EMPTY WALLET
                      itemElements.length === 0 ?
                          (
                          <div className="collection-main">
                                <div className="collection-not-connected">
                                  <div className="big-message">
                                    {t('wallet.empty.text1')}
                                  </div>
                                  <div className="collection-second-message">
                                    {t('wallet.empty.text2')}
                                  </div>
                                  <div className="not-logged-collection-images">
                                    <img className="animated-collection" src={d1Img} alt=""/>
                                    <img className="animated-collection2" src={d2Img} alt=""/>
                                  </div>
                                  <div className="glow-wrap">
                                    <i className="glow"></i>
                                  </div>
                                </div>
                              </div>
                          ) :
                          (
                              // ITEMS AVAILABLE FOR SALE
                              <div className="collection-main">
                                <AutoSizer className="react-virtualized-auto-sizer">
                                  {({height, width}) => {
                                    const itemWidth = 370;
                                    const columnCount = Math.floor(width / itemWidth);
                                    const columnWidth = itemWidth;
                                    const rowCount = Math.ceil(itemElements.length / columnCount);
                                    const rowHeight = 350;

                                    const cellRenderer = ({columnIndex, key, rowIndex, style}) => {
                                      const index = rowIndex * columnCount + columnIndex;
                                      if (index >= itemElements.length) {
                                        return null;
                                      }

                                      return (
                                          <div key={key} style={{ ...style, padding: 30}}>
                                            {itemElements[index]}
                                          </div>
                                      );
                                    };

                                    return (
                                        <div className="react-virtualized-div-fix">
                                          <Grid
                                              cellRenderer={cellRenderer}
                                              columnCount={columnCount}
                                              columnWidth={columnWidth}
                                              height={height}
                                              rowCount={rowCount}
                                              rowHeight={rowHeight}
                                              width={width}
                                          />
                                        </div>
                                    );
                                  }}
                                </AutoSizer>
                              </div>
                          )
              )
        }

      </div>
              );
            }
          }


export default withTranslation()(SkinHomeComponent);