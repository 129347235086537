import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: true,
        resources: {
            en: {
                translation: {
                    "pending.nft" : "\n" +
                        "This wallet has one NFT pending. It may take a few minutes to become available on the blockchain.",
                    "wallet.empty.text1" : "This wallet seems to be empty.",
                    "wallet.empty.text2" : "Explore and add your favorite watches via the left menu ✨",
                    "change.name.success" : "Congratulations, the name change was successful. Please note that it may take a few seconds for the change to reflect.",
                    "search" : "Search collection",
                    "connect.wallet" : "Connect Wallet",
                    "mint.success" : "Congratulations! 🎉 Your NFT has been successfully minted! 🚀 Please note: it may take a few minutes for the transaction to be reflected in your wallet.✨",
                    "mint.fail" : "Connection failed, please try again later.",
                    "back.to.collection" : "Back to Collection",
                    "form.create.nft.submit" : "Add into Blockchain",
                    "form.metadata" : "Optional. Enter any additional information about this piece.",
                    "form.serial.number" : "Optional. Enter the serial number of your item. For your security and privacy, the serial number will be encrypted before upload, ensuring it remains inaccessible to anyone, including our platform. This encryption allows for future verification of the item's originality. If you have the original serial number, you can verify its authenticity by matching it against this encrypted version",
                    "form.year" : "Required. Enter the year of manufacture",
                    "form.price" : "Optional. Enter the price paid in US Dollars. This value will be used to calculate your portfolio's value and track its variations over time, providing a clear picture of the financial performance of your collection.",
                    "form.model" : "Required. Select a brand from the suggested list or enter it manually. ",
                    "form.brand" : "Required.  Select a brand from the suggested list or enter it manually.",
                    "form.wallet" : "Required. This is your unique wallet identifier. It will be automatically filled in when you connect your wallet",
                    "top.brands.month" : "Top brands of the month",
                    "top.countries.month" : "Top countries of the month",
                    "recent.additions" : "Recent additions",
                    "transfer" : "Transfer",
                    "change.name" : "Change name",
                    "ultra.rares" : "Ultra Rares",
                    "locations": "Locations",
                    "avg.value" : "Average Value",
                    "client.mint.explanation" : "When creating your NFT, you are digitizing a real watch into a digital version. This digital certificate will be stored in your wallet and visible in your NFT collection. \n \n Initially, the watch will have an \"Unverified\" tag. You can request a verification process for the watch. Alternatively, purchasing from a certified seller will immediately provide you with a verified NFT.",
                    "new.watch" : "New Watch",
                    "menu.collection": "Collection",
                    "menu.about": "About",
                    "menu,partner": "Partner",
                    "menu.skin": "Skins",
                    "connected": "Connected",
                    "mission": "Mission",
                    "purchase.history": "Purchase History",
                    "fraud.prevention": "Fraud Prevention",
                    "digital.showroom": "Digital Showroom",
                    "global.recipe.warranty": "Global Recipe & Warranty",
                    "decentralized": "100% Decentralized",
                    "zero.web2": "Zero web2",
                    "non.fungible.token": "Non-Fungible-Token",
                    "ethereum": "Ethereum",
                    "most.reliable.network": "The most reliable network",
                    "no.database": "No database.",
                    "storage.uses.ipfs": "Storage uses IPFS to guarantee immutability forever",
                    "store.every.watch" : "Store every watch certificate forever",
                    "bridge.to.blockchain" : "A Bridge for watches to the blockchain",
                    "home.title1" : "From Wrist to Blockchain",
                    "home.title2" : "Unlock the true value of your watch with an NFT verification system",
                    "connect.text2" : "After connecting your wallet, you will be able to manage your collection and set a unique name to show it.",
                    "connect.text1" : "Connect your Metamask wallet to unveil your collection.",
                    "click.here" : "Click here",
                    "about1" : "Watches are not just valued historical artifacts, but also potent symbols of luxury on a global scale. In the rapidly evolving world of Real World Assets (RWAs), they stand as testament to how tangible assets are changing the landscape of investment, trade, and appreciation, signaling a prominent trend in the modern era. Our platform is uniquely designed to offer a trustworthy and secure solution for enthusiasts and businesses to create NFTs for their prized watches.",
                    "about2" : "By leveraging the Ethereum blockchain and incorporating advanced ZkEVM technology from Linea, a leader in blockchain partnerships, we ensure unmatched security, transparency, and a permanent record of ownership and transaction histories. Furthermore, we utilize IPFS for decentralized storage, enhancing access and durability. To guarantee the immutability of NFT information, we employ Arweave, a cutting-edge blockchain solution that ensures data is permanently unalterable and publicly verifiable, even beyond our control. Arweave stands out in the crypto space for its novel approach to data preservation, enabling secure, long-term storage. ",
                    "about3" : " While we tap into the benefits of decentralized technologies, we balance it with the usability of a centralized system, enhancing the user experience and streamlining business needs. The emergence of NFTs through our platform not only acts as a deterrent to counterfeiting and theft but also heralds novel avenues in the metaverse's virtual watch domain. Opting against a proprietary token, we position our platform as a vanguard for watch NFTs, all the while nurturing a vibrant, community-focused ecosystem.",
                    "partnership" : "Partnership",
                    "seeking.partnership" : "We are seeking partners to enhance the platform. Contact us at contact@watchdex.io",
                    "attention" : "Be careful: Watchdex does not have tokens for sale",
                    "form.new.name" : "New name",
                    "form.new.name.explanation" : "Max 24 characters, no spaces",
                    "form.button.set.name" : "Confirm new name",
                    "function.change.name.explanation" : "By setting a name, the owner can share a specific link of the collection with others, making it easily accessible and shareable. Moreover, this unique name makes the collection searchable, enhancing its visibility. It's an effective way to personalize and highlight your collection in the digital NFT universe. Once registered, the name not only identifies the collection but also serves as a distinctive mark, adding value and recognition to it.",
                    "skins.empty1" : "Mercado vazio ",
                    "skins.empty2" : "nenhuma skin a venda no momento"
                }
            },

    "pt-BR": {
    "translation": {
        "pending.nft" : "Esta carteira tem um NFT pendente. Pode levar alguns minutos para ficar disponível na blockchain.",
        "wallet.empty.text1" : "Esta carteira parece estar vazia.",
        "wallet.empty.text2" : "Explore e adicione seus relógios favoritos pelo menu à esquerda ✨",
        "search": "Buscar coleção",
            "change.name.success" : "Parabéns, a mudança de nome ocorreu com sucesso. Observe que pode demorar alguns segundos até refletir a mudança.",
            "connect.wallet": "Conectar Carteira",
            "mint.success": "Parabéns! 🎉 Sua NFT foi cunhada com sucesso! 🚀 Fique atento: pode levar alguns minutos até que a transação seja refletida na sua carteira. Tenha um pouco de paciência e em breve você verá sua nova NFT brilhando lá! 🌟✨",
            "mint.fail": "Falha na conexão, por favor tente novamente mais tarde.",
            "back.to.collection": "Voltar para coleção",
            "form.create.nft.submit": "Adicionar na Blockchain",
            "form.metadata": "Opcional. Insira informações adicionais sobre esta peça.",
            "form.serial.number": "Opcional. Insira o número de série do item. Para sua segurança e privacidade, o número de série será criptografado antes do upload, garantindo que permaneça inacessível a qualquer pessoa, incluindo nossa plataforma. Essa criptografia permite a verificação futura da originalidade do item. Se você possui o número de série original, pode verificar sua autenticidade comparando-o com esta versão criptografada.",
            "form.year": "Obrigatório. Insira o ano de fabricação.",
            "form.price": "Opcional. Preço pago em US Dollars.",
            "form.model": "Obrigatório. Selecione uma marca da lista sugerida ou insira manualmente.",
            "form.brand": "Obrigatório. Selecione uma marca da lista sugerida ou insira manualmente.",
            "form.wallet": "Obrigatório. Este é o identificador único da sua carteira. Será preenchido automaticamente ao conectar sua carteira.",
            "top.brands.month": "Principais marcas do mês",
            "top.countries.month": "Principais países do mês",
            "recent.additions": "Adições recentes",
            "transfer": "Transferir",
            "change.name": "Alterar nome",
            "ultra.rares": "Ultra Raros",
            "locations": "Localizações",
            "avg.value": "Valor aproximado",
            "client.mint.explanation": "Ao criar seu NFT, você está transformando um relógio real em uma versão digital. Este certificado digital será armazenado em sua carteira e visível em sua coleção de NFTs. Inicialmente, o relógio terá uma tag de 'Não Verificado'. Você pode solicitar um processo de verificação para o relógio. Alternativamente, ao comprar de um vendedor certificado, você receberá imediatamente um NFT verificado.",
            "new.watch": "Novo Relógio",
            "menu.collection": "Coleção",
            "menu.about": "Sobre",
            "menu.partner": "Parceiros",
            "menu.skins": "Skins",
            "connected": "Conectado",
            "mission": "Missão",
            "purchase.history": "Histórico de Compra",
            "fraud.prevention": "Prevenção a Fraude e Falsificações",
            "digital.showroom": "Showroom Digital",
            "global.recipe.warranty": "Nota e Garantia Global",
            "decentralized": "100% Descentralizado",
            "zero.web2": "Zero web2",
            "non.fungible.token": "Token Não-Fungível",
            "ethereum": "Ethereum",
            "most.reliable.network": "A rede mais confiável",
            "no.database": "Sem base de dados.",
            "storage.uses.ipfs": "O armazenamento usa IPFS para garantir imutabilidade para sempre",
            "store.every.watch": "Armazene um certificado de cada relógio para sempre",
            "bridge.to.blockchain": "Uma ponte para a blockchain",
            "home.title1": "Do pulso para a blockchain",
            "home.title2": "Destrave o verdadeiro valor do seu relógio com um sistema de verificação com NFTs",
            "connect.text1": "Conecte sua carteira Metamask e revele sua coleção.",
            "connect.text2": "Após conectar sua carteira, você poderá gerenciar sua coleção e definir um nome único.",
            "click.here": "Clique aqui",
            "about1": "Relógios não são apenas valiosos artefatos históricos, mas também potentes símbolos de luxo em escala global. No mundo em rápida evolução dos Ativos do Mundo Real (RWAs), eles atestam como ativos tangíveis estão mudando a paisagem de investimento, comércio e apreciação, sinalizando uma tendência proeminente na era moderna. Nossa plataforma é projetada de forma única para oferecer uma solução confiável e segura para entusiastas e empresas criarem NFTs para seus relógios valorizados..",
            "about2": "Ao aproveitar a blockchain Ethereum e incorporar a avançada tecnologia ZkEVM da Linea, líder em parcerias blockchain, garantimos segurança, transparência e um registro permanente de propriedade e históricos de transações inigualáveis. Além disso, utilizamos o IPFS para armazenamento descentralizado, melhorando o acesso e a durabilidade. Para garantir a imutabilidade das informações das NFTs, empregamos o Arweave, uma solução blockchain de ponta que assegura que os dados sejam permanentemente inalteráveis e publicamente verificáveis, mesmo além do nosso controle. O Arweave destaca-se no espaço cripto por sua abordagem inovadora na preservação de dados, possibilitando um armazenamento seguro e de longo prazo",
            "about3": "Enquanto aproveitamos os benefícios das tecnologias descentralizadas, as balanceamos com a usabilidade de um sistema centralizado, melhorando a experiência do usuário e otimizando as necessidades empresariais. O surgimento de NFTs por meio de nossa plataforma não só atua como um inibidor para a falsificação e roubo, mas também inaugura novas vias no domínio dos relógios virtuais no metaverso. Optando contra um token proprietário, posicionamos nossa plataforma como uma vanguarda para NFTs de relógios, cultivando um ecossistema focado e vibrante na comunidade.",
            "partnership": "Parceria",
            "seeking.partnership": "Estamos em busca de parceiros para aprimorar a plataforma. Entre em contato conosco em contact@watchdex.io",
            "attention": "Tenha cuidado: Watchdex não tem token à venda",
            "form.new.name": "Novo nome",
            "form.new.name.explanation": "Max 24 characteres, sem espaços",
            "form.button.set.name": "Confirmar novo nome",
            "function.change.name.explanation": "Ao definir um nome, o proprietário pode compartilhar um link específico da coleção com outras pessoas, facilitando o acesso e a divulgação. Além disso, esse nome único torna a coleção pesquisável, aumentando sua visibilidade. É uma maneira eficaz de personalizar e destacar sua coleção no universo digital dos NFTs. Uma vez registrado, o nome não apenas identifica a coleção, mas também serve como uma marca distintiva, agregando valor e reconhecimento à mesma.",
             "skins.empty1" : "Mercado vazio ",
             "skins.empty2" : "nenhuma skin a venda no momento"

    }
}


}
    });

export default i18next;
