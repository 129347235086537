export default class WatchHelper {

   static getWatchModels() {
   return [
      {
         "name":"100"
      },
      {
         "name":"104"
      },
      {
         "name":"140"
      },
      {
         "name":"156"
      },
      {
         "name":"157"
      },
      {
         "name":"1881 Automatic"
      },
      {
         "name":"1911 Tekton"
      },
      {
         "name":"1948"
      },
      {
         "name":"1966"
      },
      {
         "name":"1972"
      },
      {
         "name":"21 Chronoscaph"
      },
      {
         "name":"21 Must de Cartier"
      },
      {
         "name":"240"
      },
      {
         "name":"303"
      },
      {
         "name":"500 Fathoms"
      },
      {
         "name":"5 Sports"
      },
      {
         "name":"6000"
      },
      {
         "name":"917"
      },
      {
         "name":"Accutron II"
      },
      {
         "name":"Adhaesio"
      },
      {
         "name":"Admiral's Cup AC-One"
      },
      {
         "name":"Admiral's Cup Legend 38"
      },
      {
         "name":"Admiral's Cup Legend 42"
      },
      {
         "name":"Aerospace Avantage"
      },
      {
         "name":"Aerospace EVO"
      },
      {
         "name":"AirBoss"
      },
      {
         "name":"Air King"
      },
      {
         "name":"Air King Date"
      },
      {
         "name":"Airman 17"
      },
      {
         "name":"Airman 18"
      },
      {
         "name":"Airman Base 22"
      },
      {
         "name":"All Dial"
      },
      {
         "name":"Alliance"
      },
      {
         "name":"Alpiner"
      },
      {
         "name":"Alpnach"
      },
      {
         "name":"ALT1-B"
      },
      {
         "name":"ALT1-WT World Timer"
      },
      {
         "name":"ALT1-Z Zulu"
      },
      {
         "name":"Alter Ego"
      },
      {
         "name":"Ambassador"
      },
      {
         "name":"AMVOX"
      },
      {
         "name":"Antares"
      },
      {
         "name":"ANU"
      },
      {
         "name":"Apache"
      },
      {
         "name":"Aquaracer"
      },
      {
         "name":"Aquaracer 300M"
      },
      {
         "name":"Aquaracer 500M"
      },
      {
         "name":"Aquaracer Lady"
      },
      {
         "name":"Aquis Chronograph"
      },
      {
         "name":"Aquis Date"
      },
      {
         "name":"Aquis Depth Gauge"
      },
      {
         "name":"Aquis Small Second"
      },
      {
         "name":"Aquis Titan"
      },
      {
         "name":"Arizona"
      },
      {
         "name":"Artelier Alarm"
      },
      {
         "name":"Artelier Chronograph"
      },
      {
         "name":"Artelier Complication"
      },
      {
         "name":"Artelier Date"
      },
      {
         "name":"Artelier Skeleton"
      },
      {
         "name":"Artelier Small Second"
      },
      {
         "name":"Artelier Translucent Skeleton"
      },
      {
         "name":"Artelier Worldtimer"
      },
      {
         "name":"Artena"
      },
      {
         "name":"Artix Chronograph"
      },
      {
         "name":"Artix Complication"
      },
      {
         "name":"Artix GT"
      },
      {
         "name":"Artix Pointer"
      },
      {
         "name":"Astrographic"
      },
      {
         "name":"Astron GPS Solar"
      },
      {
         "name":"Astron GPS Solar Chronograph"
      },
      {
         "name":"Audi Sport"
      },
      {
         "name":"Autavia"
      },
      {
         "name":"Avalanche"
      },
      {
         "name":"Avenger Blackbird"
      },
      {
         "name":"Avenger Blackbird 44"
      },
      {
         "name":"Avenger II"
      },
      {
         "name":"Avenger II GMT"
      },
      {
         "name":"Avenger II Seawolf"
      },
      {
         "name":"Avenger Skyland"
      },
      {
         "name":"Avenue"
      },
      {
         "name":"B-1"
      },
      {
         "name":"B-2"
      },
      {
         "name":"B-42 Black"
      },
      {
         "name":"B-42 Flieger"
      },
      {
         "name":"B-42 Marinemaster"
      },
      {
         "name":"B-42 Monolith"
      },
      {
         "name":"B-42 Official Cosmonauts"
      },
      {
         "name":"B-42 Stratoliner"
      },
      {
         "name":"B-47"
      },
      {
         "name":"Baignoire"
      },
      {
         "name":"Ballon Blanc"
      },
      {
         "name":"Ballon Bleu"
      },
      {
         "name":"Ballon Bleu 28mm"
      },
      {
         "name":"Ballon Bleu 33mm"
      },
      {
         "name":"Ballon Bleu 36mm"
      },
      {
         "name":"Ballon Bleu 40mm"
      },
      {
         "name":"Ballon Bleu 42mm"
      },
      {
         "name":"Ballon Bleu 44mm"
      },
      {
         "name":"Baroncelli"
      },
      {
         "name":"Baroncelli Chronograph"
      },
      {
         "name":"Baroncelli II"
      },
      {
         "name":"Baroncelli III"
      },
      {
         "name":"Baroncelli Moonphase"
      },
      {
         "name":"Base Camp"
      },
      {
         "name":"BC3"
      },
      {
         "name":"Belluna"
      },
      {
         "name":"Bentley B04 GMT"
      },
      {
         "name":"Bentley B05 Unitime"
      },
      {
         "name":"Bentley B06"
      },
      {
         "name":"Bentley Barnato"
      },
      {
         "name":"Bentley Supersports"
      },
      {
         "name":"Big Bang 38 mm"
      },
      {
         "name":"Big Bang 41 mm"
      },
      {
         "name":"Big Bang 44 mm"
      },
      {
         "name":"Big Bang Caviar"
      },
      {
         "name":"Big Bang Ferrari"
      },
      {
         "name":"Big Bang Jeans"
      },
      {
         "name":"Big Bang Pop Art"
      },
      {
         "name":"Big Bang Unico"
      },
      {
         "name":"Big Crown Complication"
      },
      {
         "name":"Big Crown Pointer Date"
      },
      {
         "name":"Big Crown ProPilot"
      },
      {
         "name":"Big Crown ProPilot Altimeter"
      },
      {
         "name":"Big Crown ProPilot Chronograph"
      },
      {
         "name":"Big Crown ProPilot Date"
      },
      {
         "name":"Big Crown Small Second Pointer Day"
      },
      {
         "name":"Big Crown Timer"
      },
      {
         "name":"Big Crown X1"
      },
      {
         "name":"Bison"
      },
      {
         "name":"Black Reef"
      },
      {
         "name":"Blue Manta"
      },
      {
         "name":"Blue Seal"
      },
      {
         "name":"Boeing"
      },
      {
         "name":"Bogner by Junghans"
      },
      {
         "name":"Boh\u00e8me"
      },
      {
         "name":"Bold"
      },
      {
         "name":"BR 01-92"
      },
      {
         "name":"BR 01-93 GMT"
      },
      {
         "name":"BR 01-94 Chronographe"
      },
      {
         "name":"BR 01-96 Grande Date"
      },
      {
         "name":"BR 01-97 R\u00e9serve de Marche"
      },
      {
         "name":"BR 03-51 GMT"
      },
      {
         "name":"BR 03-90 Grande Date et Reserve de Marche"
      },
      {
         "name":"BR 03-92 Ceramic"
      },
      {
         "name":"BR 03-92 Steel"
      },
      {
         "name":"BR 03-94 Chronographe"
      },
      {
         "name":"BR 03-96 Grande Date"
      },
      {
         "name":"BR 03-97 R\u00e9serve de Marche"
      },
      {
         "name":"Bridgeport"
      },
      {
         "name":"BR S"
      },
      {
         "name":"BR-X1"
      },
      {
         "name":"Bubble"
      },
      {
         "name":"C1"
      },
      {
         "name":"C2"
      },
      {
         "name":"Calibre de Cartier"
      },
      {
         "name":"Callistino"
      },
      {
         "name":"Callisto"
      },
      {
         "name":"Calobra"
      },
      {
         "name":"Calypso"
      },
      {
         "name":"Carrera"
      },
      {
         "name":"Carrera Calibre 16"
      },
      {
         "name":"Carrera Calibre 17"
      },
      {
         "name":"Carrera Calibre 1887"
      },
      {
         "name":"Carrera Calibre 36"
      },
      {
         "name":"Carrera Calibre 5"
      },
      {
         "name":"Carrera Calibre 6"
      },
      {
         "name":"Carrera Calibre 7"
      },
      {
         "name":"Carrera Calibre 8"
      },
      {
         "name":"Carrera Lady"
      },
      {
         "name":"Carrera Mikrograph"
      },
      {
         "name":"Carson"
      },
      {
         "name":"Cat's Eye"
      },
      {
         "name":"Catwalk"
      },
      {
         "name":"Cellini"
      },
      {
         "name":"Cellini Danaos"
      },
      {
         "name":"Cellini Date"
      },
      {
         "name":"Cellini Dual Time"
      },
      {
         "name":"Cellini Moonphase"
      },
      {
         "name":"Cellini Prince"
      },
      {
         "name":"Cellini Time"
      },
      {
         "name":"Centenaire"
      },
      {
         "name":"Chimera"
      },
      {
         "name":"Chrono Classic"
      },
      {
         "name":"Chronofighter 1695"
      },
      {
         "name":"Chronofighter Oversize"
      },
      {
         "name":"Chronofighter R.A.C."
      },
      {
         "name":"Chronograph"
      },
      {
         "name":"Chrono Hawk"
      },
      {
         "name":"Chronoliner"
      },
      {
         "name":"Chronomat"
      },
      {
         "name":"Chronomat 38"
      },
      {
         "name":"Chronomat 41"
      },
      {
         "name":"Chronomat 44"
      },
      {
         "name":"Chronomat 44 Airborne"
      },
      {
         "name":"Chronomat 44 GMT"
      },
      {
         "name":"Chronomat 44 Raven"
      },
      {
         "name":"Chronomat GMT"
      },
      {
         "name":"Chrono-Matic"
      },
      {
         "name":"Chrono-Matic 49"
      },
      {
         "name":"Chronoris"
      },
      {
         "name":"Chronospace Automatic"
      },
      {
         "name":"Chronospace Military"
      },
      {
         "name":"Circa"
      },
      {
         "name":"Circularis"
      },
      {
         "name":"Classic"
      },
      {
         "name":"Classic"
      },
      {
         "name":"Classic"
      },
      {
         "name":"Classic"
      },
      {
         "name":"Classic Edition"
      },
      {
         "name":"Classic Fusion"
      },
      {
         "name":"Classic Fusion Chronograph"
      },
      {
         "name":"Classic Hexagon"
      },
      {
         "name":"Classico"
      },
      {
         "name":"Classico Luna"
      },
      {
         "name":"Classics"
      },
      {
         "name":"Classics"
      },
      {
         "name":"Classique Complications"
      },
      {
         "name":"Class One"
      },
      {
         "name":"Cl\u00e9 de Cartier"
      },
      {
         "name":"Club"
      },
      {
         "name":"Cockpit"
      },
      {
         "name":"Cockpit B50"
      },
      {
         "name":"Cockpit Lady"
      },
      {
         "name":"Codebreaker"
      },
      {
         "name":"Coin Watch"
      },
      {
         "name":"Colormark"
      },
      {
         "name":"Colors"
      },
      {
         "name":"Colt 36"
      },
      {
         "name":"Colt 44"
      },
      {
         "name":"Colt Automatic"
      },
      {
         "name":"Colt Chronograph"
      },
      {
         "name":"Colt Chronograph Automatic"
      },
      {
         "name":"Colt Chronograph II"
      },
      {
         "name":"Colt GMT+"
      },
      {
         "name":"Colt Lady"
      },
      {
         "name":"Combat 7"
      },
      {
         "name":"Combat SUB"
      },
      {
         "name":"Commander"
      },
      {
         "name":"Comtesse"
      },
      {
         "name":"Conductor"
      },
      {
         "name":"Conquest Classic"
      },
      {
         "name":"Constellation Day-Date"
      },
      {
         "name":"Constellation Men"
      },
      {
         "name":"Constellation Quartz"
      },
      {
         "name":"Contessa"
      },
      {
         "name":"Coupole"
      },
      {
         "name":"Crash"
      },
      {
         "name":"Credor"
      },
      {
         "name":"Crosswind Special"
      },
      {
         "name":"Cruise"
      },
      {
         "name":"Crystal"
      },
      {
         "name":"CT60"
      },
      {
         "name":"Dandy"
      },
      {
         "name":"Daniel Roth"
      },
      {
         "name":"Datejust"
      },
      {
         "name":"Datejust 31"
      },
      {
         "name":"Datejust 36"
      },
      {
         "name":"Datejust 41"
      },
      {
         "name":"Datejust II"
      },
      {
         "name":"Datejust Oysterquartz"
      },
      {
         "name":"Datejust Turn-O-Graph"
      },
      {
         "name":"Day-Date"
      },
      {
         "name":"Day-Date 36"
      },
      {
         "name":"Day-Date 40"
      },
      {
         "name":"Day-Date II"
      },
      {
         "name":"Day-Date Oysterquartz"
      },
      {
         "name":"Daytona"
      },
      {
         "name":"Deep Dive"
      },
      {
         "name":"Deep Sea"
      },
      {
         "name":"Deep Sea Chronograph"
      },
      {
         "name":"Delirium"
      },
      {
         "name":"De Ville Tr\u00e9sor"
      },
      {
         "name":"Diamond"
      },
      {
         "name":"Dive Master 500"
      },
      {
         "name":"Diver"
      },
      {
         "name":"Divers Sixty Five"
      },
      {
         "name":"Divers Titan"
      },
      {
         "name":"D-Star"
      },
      {
         "name":"D-Star 200"
      },
      {
         "name":"Duograph"
      },
      {
         "name":"Easy Diver"
      },
      {
         "name":"Edward Piguet"
      },
      {
         "name":"Elegant"
      },
      {
         "name":"Eliro"
      },
      {
         "name":"Elite 6150"
      },
      {
         "name":"Elite Power Reserve"
      },
      {
         "name":"Elite Ultra Thin"
      },
      {
         "name":"Elliptica"
      },
      {
         "name":"El Primero 36'000 VpH"
      },
      {
         "name":"El Primero 410"
      },
      {
         "name":"El Primero Chronograph"
      },
      {
         "name":"El Primero Espada"
      },
      {
         "name":"El Primero Lightweight"
      },
      {
         "name":"El Primero Original 1969"
      },
      {
         "name":"El Primero Stratos Flyback"
      },
      {
         "name":"El Primero Synopsis"
      },
      {
         "name":"El Primero Tourbillon"
      },
      {
         "name":"El Primero Winsor Annual Calendar"
      },
      {
         "name":"Engineer Hydrocarbon"
      },
      {
         "name":"Engineer Hydrocarbon Deepquest"
      },
      {
         "name":"Engineer Hydrocarbon GMT"
      },
      {
         "name":"Engineer Hydrocarbon Magnate"
      },
      {
         "name":"Engineer Hydrocarbon Nedu"
      },
      {
         "name":"Engineer Hydrocarbon Spacemaster"
      },
      {
         "name":"Engineer II"
      },
      {
         "name":"Engineer II Arabic"
      },
      {
         "name":"Engineer II Chronometer Red Label"
      },
      {
         "name":"Engineer II Magneto S"
      },
      {
         "name":"Engineer II Marvelight"
      },
      {
         "name":"Engineer II Ohio"
      },
      {
         "name":"Engineer Master II"
      },
      {
         "name":"Engineer Master II Aviator"
      },
      {
         "name":"Engineer Master II Diver"
      },
      {
         "name":"Engineer Master II Skindiver"
      },
      {
         "name":"Esperanza"
      },
      {
         "name":"E-Type"
      },
      {
         "name":"Evo"
      },
      {
         "name":"Excalibur"
      },
      {
         "name":"Executive Edition"
      },
      {
         "name":"Explorer"
      },
      {
         "name":"Explorer II"
      },
      {
         "name":"EZM 10"
      },
      {
         "name":"EZM 13"
      },
      {
         "name":"F-117 Nighthawk"
      },
      {
         "name":"F-22 Raptor"
      },
      {
         "name":"F-35 Lightning II"
      },
      {
         "name":"F-43"
      },
      {
         "name":"Fastrider Black Shield"
      },
      {
         "name":"Fastrider Chrono"
      },
      {
         "name":"Ferrari"
      },
      {
         "name":"Field Automatic"
      },
      {
         "name":"Fiero"
      },
      {
         "name":"Fifty Fathoms Bathyscaphe"
      },
      {
         "name":"Fireman"
      },
      {
         "name":"Fireman Classic"
      },
      {
         "name":"Fireman Racer"
      },
      {
         "name":"Fireman Storm Chaser"
      },
      {
         "name":"Fireman Victory"
      },
      {
         "name":"Flieger"
      },
      {
         "name":"Flightmaster"
      },
      {
         "name":"Florence"
      },
      {
         "name":"for BMW"
      },
      {
         "name":"Force Mega Solar"
      },
      {
         "name":"Formula 1"
      },
      {
         "name":"Formula 1 Calibre 16"
      },
      {
         "name":"Formula 1 Calibre 5"
      },
      {
         "name":"Formula 1 Calibre 7"
      },
      {
         "name":"Formula 1 Lady"
      },
      {
         "name":"Freak"
      },
      {
         "name":"Freak Cruiser"
      },
      {
         "name":"Galactic"
      },
      {
         "name":"Galactic 44"
      },
      {
         "name":"Galactic Unitime"
      },
      {
         "name":"Galante"
      },
      {
         "name":"Geneve"
      },
      {
         "name":"Genghis Khan"
      },
      {
         "name":"Geophysic"
      },
      {
         "name":"Glamour Date"
      },
      {
         "name":"Glamour Date-Day"
      },
      {
         "name":"Glamour Double Date"
      },
      {
         "name":"Globemaster"
      },
      {
         "name":"GMT +/- Perpetual"
      },
      {
         "name":"GMT-Master"
      },
      {
         "name":"GMT-Master II"
      },
      {
         "name":"Golden Square"
      },
      {
         "name":"Graf Berghe von Trips"
      },
      {
         "name":"Grande Reverso"
      },
      {
         "name":"Grantour Chrono"
      },
      {
         "name":"Grantour Chrono Fly-Back"
      },
      {
         "name":"Grantour Date"
      },
      {
         "name":"Gravity"
      },
      {
         "name":"Great Wall"
      },
      {
         "name":"Harmony"
      },
      {
         "name":"Headwind"
      },
      {
         "name":"Helvetica"
      },
      {
         "name":"Heritage Chrono Blue"
      },
      {
         "name":"Heritage Chronom\u00e9trie"
      },
      {
         "name":"Heritage Ranger"
      },
      {
         "name":"Heritage Spirit"
      },
      {
         "name":"Heritage Visodate"
      },
      {
         "name":"Hommage"
      },
      {
         "name":"Horological Smartwatch"
      },
      {
         "name":"Hour Glass"
      },
      {
         "name":"I.N.O.X."
      },
      {
         "name":"Impresario"
      },
      {
         "name":"Infantry"
      },
      {
         "name":"Infantry Vintage"
      },
      {
         "name":"Jaguar"
      },
      {
         "name":"Jazzmaster Day Date Auto"
      },
      {
         "name":"Jazzmaster GMT Auto"
      },
      {
         "name":"Jochen Mass"
      },
      {
         "name":"Jules Audemars"
      },
      {
         "name":"Jupiter Pilot"
      },
      {
         "name":"Juro"
      },
      {
         "name":"Khaki Field Day Date"
      },
      {
         "name":"Khaki Field King"
      },
      {
         "name":"Khaki Field Pioneer"
      },
      {
         "name":"Khaki Field Skeleton"
      },
      {
         "name":"Khaki Field Titanium"
      },
      {
         "name":"Khaki Navy Pioneer"
      },
      {
         "name":"Khaki Navy Scuba"
      },
      {
         "name":"Khaki Navy Sub"
      },
      {
         "name":"Khaki Navy UTC"
      },
      {
         "name":"Khesis"
      },
      {
         "name":"Kingsman"
      },
      {
         "name":"KingSquare"
      },
      {
         "name":"Kirium"
      },
      {
         "name":"KMU48"
      },
      {
         "name":"Kontiki"
      },
      {
         "name":"La Dona de Cartier"
      },
      {
         "name":"Lady-Datejust"
      },
      {
         "name":"Lady-Datejust Pearlmaster"
      },
      {
         "name":"Lady J"
      },
      {
         "name":"La Mon\u00e9gasque"
      },
      {
         "name":"Lani\u00e8res"
      },
      {
         "name":"La Scala"
      },
      {
         "name":"L\u00e9man Fly-Back"
      },
      {
         "name":"L\u00e9man Moonphase"
      },
      {
         "name":"L\u00e9man R\u00e9veil GMT"
      },
      {
         "name":"L\u00e9man Tourbillon"
      },
      {
         "name":"L\u00e9man Ultra Slim"
      },
      {
         "name":"Le Modulor"
      },
      {
         "name":"Les Classiques Chronographe"
      },
      {
         "name":"Les Classiques Date"
      },
      {
         "name":"Les Classiques Phases de Lune"
      },
      {
         "name":"Les Classiques Tradition"
      },
      {
         "name":"Lichine"
      },
      {
         "name":"Liens"
      },
      {
         "name":"Lindbergh Hour Angle"
      },
      {
         "name":"Link"
      },
      {
         "name":"Link Calibre 16"
      },
      {
         "name":"Link Calibre 18"
      },
      {
         "name":"Link Calibre 5"
      },
      {
         "name":"Link Calibre 6"
      },
      {
         "name":"Link Calibre 7"
      },
      {
         "name":"Link Lady"
      },
      {
         "name":"Link Quartz"
      },
      {
         "name":"Lucea"
      },
      {
         "name":"Luminor"
      },
      {
         "name":"Luminor 1950 10 Days GMT"
      },
      {
         "name":"Luminor 1950 3 Days GMT Automatic"
      },
      {
         "name":"Luminor 1950 3 Days GMT Power Reserve Automatic"
      },
      {
         "name":"Luminor 1950 8 Days Chrono Monopulsante GMT"
      },
      {
         "name":"Luminor 1950 8 Days GMT"
      },
      {
         "name":"Luminor Base"
      },
      {
         "name":"Luminor Chrono"
      },
      {
         "name":"Luminor GMT Automatic"
      },
      {
         "name":"Luminor Marina"
      },
      {
         "name":"Luminor Marina 1950 3 Days Automatic"
      },
      {
         "name":"Luminor Marina Automatic"
      },
      {
         "name":"Luminor Power Reserve"
      },
      {
         "name":"Luminor Submersible"
      },
      {
         "name":"Luxury Automatic"
      },
      {
         "name":"M1"
      },
      {
         "name":"M2"
      },
      {
         "name":"M2-1"
      },
      {
         "name":"M2-2"
      },
      {
         "name":"Madison"
      },
      {
         "name":"Manufacture"
      },
      {
         "name":"Mare Nostrum"
      },
      {
         "name":"Marine"
      },
      {
         "name":"Marine Chronograph"
      },
      {
         "name":"Marine Chronometer 41mm"
      },
      {
         "name":"Marine Chronometer 43mm"
      },
      {
         "name":"Marine Chronometer Manufacture"
      },
      {
         "name":"Marinemaster"
      },
      {
         "name":"Mariner"
      },
      {
         "name":"Marine Star"
      },
      {
         "name":"Master Banker"
      },
      {
         "name":"Master Compressor"
      },
      {
         "name":"Master Compressor Chronograph Ceramic"
      },
      {
         "name":"Master Compressor Diving"
      },
      {
         "name":"Master Compressor Extreme"
      },
      {
         "name":"Master Control"
      },
      {
         "name":"Master Control Date"
      },
      {
         "name":"Master Grande Tradition"
      },
      {
         "name":"Masterpiece Cinq Aiguilles"
      },
      {
         "name":"Masterpiece Gravity"
      },
      {
         "name":"Masterpiece Phases de Lune"
      },
      {
         "name":"Masterpiece R\u00e9serve de Marche"
      },
      {
         "name":"Masterpiece Small Seconde"
      },
      {
         "name":"Masterpiece Squelette"
      },
      {
         "name":"Masterpiece Worldtimer"
      },
      {
         "name":"Master Ultra Thin Date"
      },
      {
         "name":"Master Ultra Thin Perpetual"
      },
      {
         "name":"Matic"
      },
      {
         "name":"Maverick"
      },
      {
         "name":"max bill"
      },
      {
         "name":"max bill Automatic"
      },
      {
         "name":"max bill Chronoscope"
      },
      {
         "name":"max bill Hand-winding"
      },
      {
         "name":"max bill Ladies"
      },
      {
         "name":"max bill Quartz"
      },
      {
         "name":"max bill Wanduhr"
      },
      {
         "name":"Meister"
      },
      {
         "name":"Meister Agenda"
      },
      {
         "name":"Meister Calendar"
      },
      {
         "name":"Meister Chronometer"
      },
      {
         "name":"Meister Chronoscope"
      },
      {
         "name":"Meister Classic"
      },
      {
         "name":"Meister Hand-winding"
      },
      {
         "name":"Meister Ladies"
      },
      {
         "name":"Meister Telemeter"
      },
      {
         "name":"Mercedes GP"
      },
      {
         "name":"Midnight"
      },
      {
         "name":"Mihewi"
      },
      {
         "name":"Milano"
      },
      {
         "name":"Miles"
      },
      {
         "name":"Miles Tonneau"
      },
      {
         "name":"Milgauss"
      },
      {
         "name":"Military"
      },
      {
         "name":"Millenary"
      },
      {
         "name":"Millenary 4101"
      },
      {
         "name":"Millenary Ladies"
      },
      {
         "name":"Miss Golden Bridge"
      },
      {
         "name":"Monaco"
      },
      {
         "name":"Monaco Calibre 12"
      },
      {
         "name":"Monaco Calibre 36"
      },
      {
         "name":"Monaco Calibre 6"
      },
      {
         "name":"Monaco Lady"
      },
      {
         "name":"Monaco V4"
      },
      {
         "name":"Monarch"
      },
      {
         "name":"Monster"
      },
      {
         "name":"Montbrillant"
      },
      {
         "name":"Montbrillant 01"
      },
      {
         "name":"Montbrillant Datora"
      },
      {
         "name":"Montbrillant L\u00e9gende"
      },
      {
         "name":"Montbrillant Olympus"
      },
      {
         "name":"Monza"
      },
      {
         "name":"MP Collection"
      },
      {
         "name":"Much More"
      },
      {
         "name":"Multifort"
      },
      {
         "name":"Multifort Chronograph"
      },
      {
         "name":"Multifort GMT"
      },
      {
         "name":"N\u00b0 01"
      },
      {
         "name":"N\u00b0 02"
      },
      {
         "name":"N\u00b0 03"
      },
      {
         "name":"Nautilus"
      },
      {
         "name":"Navitimer 01"
      },
      {
         "name":"Navitimer 01 (46 MM)"
      },
      {
         "name":"Navitimer 1461"
      },
      {
         "name":"Navitimer Cosmonaute"
      },
      {
         "name":"Navitimer GMT"
      },
      {
         "name":"Navitimer Heritage"
      },
      {
         "name":"Navy Seal Colormark"
      },
      {
         "name":"Neo"
      },
      {
         "name":"Neo Q"
      },
      {
         "name":"Night Vision"
      },
      {
         "name":"North Flag"
      },
      {
         "name":"Ocean"
      },
      {
         "name":"Ocean Star"
      },
      {
         "name":"Officer's"
      },
      {
         "name":"Old Navitimer"
      },
      {
         "name":"Oposition"
      },
      {
         "name":"Opus"
      },
      {
         "name":"Original"
      },
      {
         "name":"Original"
      },
      {
         "name":"Othello"
      },
      {
         "name":"Overseas Chronograph"
      },
      {
         "name":"Overseas Dual Time"
      },
      {
         "name":"Oyster"
      },
      {
         "name":"Oysterdate Big Block"
      },
      {
         "name":"Oysterdate Precision"
      },
      {
         "name":"Oyster Perpetual"
      },
      {
         "name":"Oyster Perpetual 26"
      },
      {
         "name":"Oyster Perpetual 28"
      },
      {
         "name":"Oyster Perpetual 31"
      },
      {
         "name":"Oyster Perpetual 34"
      },
      {
         "name":"Oyster Perpetual 36"
      },
      {
         "name":"Oyster Perpetual 39"
      },
      {
         "name":"Oyster Perpetual 41"
      },
      {
         "name":"Oyster Perpetual Date"
      },
      {
         "name":"Oyster Perpetual Lady Date"
      },
      {
         "name":"Oyster Precision"
      },
      {
         "name":"Oyster Prince"
      },
      {
         "name":"P1"
      },
      {
         "name":"P1-2"
      },
      {
         "name":"P1-3"
      },
      {
         "name":"P1-4"
      },
      {
         "name":"P1-5"
      },
      {
         "name":"P1B-1"
      },
      {
         "name":"P2"
      },
      {
         "name":"P2-1"
      },
      {
         "name":"P2-2"
      },
      {
         "name":"P3"
      },
      {
         "name":"P3-1"
      },
      {
         "name":"P3-3"
      },
      {
         "name":"P-38 Lightning"
      },
      {
         "name":"P3 BB"
      },
      {
         "name":"Pagoda"
      },
      {
         "name":"Paleograph"
      },
      {
         "name":"Pangaea Day Date"
      },
      {
         "name":"Panth\u00e8re"
      },
      {
         "name":"Pasha"
      },
      {
         "name":"Pasha C"
      },
      {
         "name":"Pasha Seatimer"
      },
      {
         "name":"Pearlmaster"
      },
      {
         "name":"Perpetual Manufacture"
      },
      {
         "name":"Pilot"
      },
      {
         "name":"Pilot Type 20 Annual Calendar"
      },
      {
         "name":"Pilot Type 20 Extra Special"
      },
      {
         "name":"Pilot Type 20 GMT"
      },
      {
         "name":"Pluton"
      },
      {
         "name":"Pontos Chronographe"
      },
      {
         "name":"Pontos Chronographe R\u00e9tro"
      },
      {
         "name":"Pontos Date"
      },
      {
         "name":"Pontos Day Date"
      },
      {
         "name":"Pontos D\u00e9centrique GMT"
      },
      {
         "name":"Pontos S"
      },
      {
         "name":"Pontos S Diver"
      },
      {
         "name":"Pontos S Extreme"
      },
      {
         "name":"Pontos S Supercharged"
      },
      {
         "name":"PR 50"
      },
      {
         "name":"PRC 200"
      },
      {
         "name":"Precision"
      },
      {
         "name":"Precisionist"
      },
      {
         "name":"Premier"
      },
      {
         "name":"Premier Automatic"
      },
      {
         "name":"Premier Kinetic Direct Drive"
      },
      {
         "name":"Premier Kinetic Perpetual"
      },
      {
         "name":"Prince"
      },
      {
         "name":"Prince Date"
      },
      {
         "name":"ProDiver"
      },
      {
         "name":"ProDiver Chronograph"
      },
      {
         "name":"ProDiver Date"
      },
      {
         "name":"ProDiver Pointer Moon"
      },
      {
         "name":"Professional Golf Watch"
      },
      {
         "name":"Project Z"
      },
      {
         "name":"Promesse"
      },
      {
         "name":"Promesse"
      },
      {
         "name":"Pulsion"
      },
      {
         "name":"Quickster"
      },
      {
         "name":"Racing"
      },
      {
         "name":"Radiomir"
      },
      {
         "name":"Radiomir 8 Days"
      },
      {
         "name":"Radiomir Black Seal"
      },
      {
         "name":"Radiomir GMT"
      },
      {
         "name":"Raid"
      },
      {
         "name":"Recon"
      },
      {
         "name":"Rectangular"
      },
      {
         "name":"Red Label"
      },
      {
         "name":"Reverso"
      },
      {
         "name":"Richmond"
      },
      {
         "name":"RM 005"
      },
      {
         "name":"RM 027"
      },
      {
         "name":"RM 029"
      },
      {
         "name":"RM 030"
      },
      {
         "name":"RM 032"
      },
      {
         "name":"RM 035"
      },
      {
         "name":"RM 052"
      },
      {
         "name":"RM 055"
      },
      {
         "name":"RM 061"
      },
      {
         "name":"Roadster"
      },
      {
         "name":"Ronde Louis Cartier"
      },
      {
         "name":"Ronde Solo de Cartier"
      },
      {
         "name":"Royal Blue Tourbillon"
      },
      {
         "name":"Royal Kontiki"
      },
      {
         "name":"Royal Oak"
      },
      {
         "name":"Royal Oak Chronograph"
      },
      {
         "name":"Royal Oak Day-Date"
      },
      {
         "name":"Royal Oak Lady"
      },
      {
         "name":"Royal Oak Offshore"
      },
      {
         "name":"Royal Oak Offshore Chronograph"
      },
      {
         "name":"Royal Oak Offshore Chronograph Volcano"
      },
      {
         "name":"Royal Oak Offshore Diver"
      },
      {
         "name":"Royal Oak Offshore Grand Prix"
      },
      {
         "name":"Royal Oak Offshore Lady"
      },
      {
         "name":"Royal Oak Offshore Tourbillon Chronograph"
      },
      {
         "name":"Royal Oak Perpetual Calendar"
      },
      {
         "name":"Royal Oak Selfwinding"
      },
      {
         "name":"Royal Oak Tourbillon"
      },
      {
         "name":"Runabout"
      },
      {
         "name":"Salthora"
      },
      {
         "name":"Salthora Meta"
      },
      {
         "name":"Santos 100"
      },
      {
         "name":"Santos Demoiselle"
      },
      {
         "name":"Santos Dumont"
      },
      {
         "name":"Sapphire Synergy"
      },
      {
         "name":"Saratoga"
      },
      {
         "name":"Scrypto"
      },
      {
         "name":"Sea Dragon"
      },
      {
         "name":"Sea-Dweller"
      },
      {
         "name":"Sea-Dweller 4000"
      },
      {
         "name":"Sea-Dweller Deepsea"
      },
      {
         "name":"Sea Hawk"
      },
      {
         "name":"Sea King"
      },
      {
         "name":"Seamaster Bullhead"
      },
      {
         "name":"Seamaster Diver 300 M"
      },
      {
         "name":"Seastrong"
      },
      {
         "name":"Seattle"
      },
      {
         "name":"Sea Wolf"
      },
      {
         "name":"SE Pilot"
      },
      {
         "name":"Shadow Flyback"
      },
      {
         "name":"Silverstone"
      },
      {
         "name":"Silverstone RS"
      },
      {
         "name":"Simply Elegant"
      },
      {
         "name":"Singular"
      },
      {
         "name":"Sky-Dweller"
      },
      {
         "name":"Slimline"
      },
      {
         "name":"Slimline Automatic"
      },
      {
         "name":"Slimline Moonphase"
      },
      {
         "name":"SLR"
      },
      {
         "name":"Soleure"
      },
      {
         "name":"Solo"
      },
      {
         "name":"Sonata"
      },
      {
         "name":"Speedmaster '57"
      },
      {
         "name":"Speedmaster HB-SIA"
      },
      {
         "name":"Speedmaster Mark II"
      },
      {
         "name":"Speedmaster Racing"
      },
      {
         "name":"Speedmaster Skywalker X-33"
      },
      {
         "name":"Spektrum"
      },
      {
         "name":"Spirit of Big Bang"
      },
      {
         "name":"Spirit of Liberty"
      },
      {
         "name":"Sport"
      },
      {
         "name":"Sportivo"
      },
      {
         "name":"Sports Edition"
      },
      {
         "name":"Sportwave"
      },
      {
         "name":"Square"
      },
      {
         "name":"SR-71 Blackbird"
      },
      {
         "name":"Star"
      },
      {
         "name":"Star Classique"
      },
      {
         "name":"Startimer"
      },
      {
         "name":"Startimer Pilot"
      },
      {
         "name":"Startimer Pilot Automatic"
      },
      {
         "name":"Startimer Pilot Heritage"
      },
      {
         "name":"Startimer Pilot Manufacture"
      },
      {
         "name":"Stop2go"
      },
      {
         "name":"Stranger"
      },
      {
         "name":"Style"
      },
      {
         "name":"Submariner"
      },
      {
         "name":"Submariner (No Date)"
      },
      {
         "name":"Submariner Date"
      },
      {
         "name":"Summit XLT"
      },
      {
         "name":"Super Avenger II"
      },
      {
         "name":"Super B"
      },
      {
         "name":"Super Kontiki"
      },
      {
         "name":"Super Kontiki Limited Edition 1973"
      },
      {
         "name":"Superocean 42"
      },
      {
         "name":"Superocean 44"
      },
      {
         "name":"Superocean Chronograph M2000"
      },
      {
         "name":"Superocean Chronograph Steelfish"
      },
      {
         "name":"Superocean GMT"
      },
      {
         "name":"Superocean Heritage 42"
      },
      {
         "name":"Superocean Heritage 46"
      },
      {
         "name":"Superocean II 36"
      },
      {
         "name":"Superocean II 42"
      },
      {
         "name":"Superocean II 44"
      },
      {
         "name":"SXC"
      },
      {
         "name":"Sympathie"
      },
      {
         "name":"Tangaroa"
      },
      {
         "name":"Tarawa"
      },
      {
         "name":"TC"
      },
      {
         "name":"T-Complication"
      },
      {
         "name":"TechnoDiamond"
      },
      {
         "name":"Thin-O-Matic"
      },
      {
         "name":"Ti-Bridge"
      },
      {
         "name":"Toccata"
      },
      {
         "name":"Tony Kanaan"
      },
      {
         "name":"Top Time"
      },
      {
         "name":"T-Race Touch"
      },
      {
         "name":"Tradition"
      },
      {
         "name":"Tradition"
      },
      {
         "name":"Traditionnelle"
      },
      {
         "name":"Trainmaster"
      },
      {
         "name":"Trainmaster Cleveland Express"
      },
      {
         "name":"Trainmaster Eternity"
      },
      {
         "name":"Trainmaster Roman"
      },
      {
         "name":"Trainmaster Worldtime"
      },
      {
         "name":"Transocean 38"
      },
      {
         "name":"Transocean Chronograph"
      },
      {
         "name":"Transocean Chronograph 1461"
      },
      {
         "name":"Transocean Chronograph 1915"
      },
      {
         "name":"Transocean Chronograph 38"
      },
      {
         "name":"Transocean Chronograph GMT"
      },
      {
         "name":"Transocean Chronograph Unitime"
      },
      {
         "name":"Transocean Day &amp; Date"
      },
      {
         "name":"Transocean Unitime Pilot"
      },
      {
         "name":"Travel Time"
      },
      {
         "name":"Tread 1"
      },
      {
         "name":"Tread 2"
      },
      {
         "name":"True Thinline"
      },
      {
         "name":"T-Touch Expert Solar"
      },
      {
         "name":"Turbine Chrono"
      },
      {
         "name":"Turbine Pilot"
      },
      {
         "name":"Turbine Poker"
      },
      {
         "name":"Turbine Yacht"
      },
      {
         "name":"Twenty~4"
      },
      {
         "name":"U-1001"
      },
      {
         "name":"U-2"
      },
      {
         "name":"U212"
      },
      {
         "name":"U-42"
      },
      {
         "name":"UF6"
      },
      {
         "name":"Ultronic"
      },
      {
         "name":"Vaughan"
      },
      {
         "name":"Velatura Chronograph"
      },
      {
         "name":"Velatura Kinetic Direct Drive"
      },
      {
         "name":"Velvet"
      },
      {
         "name":"Victoria"
      },
      {
         "name":"Villeret"
      },
      {
         "name":"Villeret Complete Calendar"
      },
      {
         "name":"Villeret Moonphase"
      },
      {
         "name":"Villeret Quanti\u00e8me Complet"
      },
      {
         "name":"Villeret Ultra-Slim"
      },
      {
         "name":"Vintage"
      },
      {
         "name":"Vintage Rally"
      },
      {
         "name":"Voyager"
      },
      {
         "name":"Wave"
      },
      {
         "name":"William Baume"
      },
      {
         "name":"Windrider"
      },
      {
         "name":"Wings Lady"
      },
      {
         "name":"Yacht-Master"
      },
      {
         "name":"Yacht-Master 37"
      },
      {
         "name":"Yacht-Master 40"
      },
      {
         "name":"Yacht-Master 42"
      },
      {
         "name":"Yacht-Master II"
      }
   ]
   }

   static getWatchBrands(){
   return [
      {"name": "Victorinox"},
      {"name" : "Baume & Mercier"},
      {"name": "Rolex"},
      {"name": "Patek Philippe"},
      {"name": "Omega"},
      {"name": "Cartier"},
      {"name": "Breitling"},
      {"name": "Audemars Piguet"},
      {"name": "Jaeger-LeCoultre"},
      {"name": "IWC Schaffhausen"},
      {"name": "Hublot"},
      {"name": "Breguet"},
      {"name": "TAG Heuer"},
      {"name": "Vacheron Constantin"},
      {"name": "Girard-Perregaux"},
      {"name": "Ulysse Nardin"},
      {"name": "Blancpain"},
      {"name": "Chopard"},
      {"name": "Zenith"},
      {"name": "Panerai"},
      {"name": "Bell & Ross"},
      {"name": "Montblanc"},
      {"name": "A. Lange & Söhne"},
      {"name": "Tudor"},
      {"name": "Bvlgari"},
      {"name": "Harry Winston"},
      {"name": "Glashütte Original"},
      {"name": "Maurice Lacroix"},
      {"name": "Oris"},
      {"name": "Parmigiani Fleurier"},
      {"name": "Corum"},
      {"name": "Ebel"},
      {"name": "F.P. Journe"},
      {"name": "Franck Muller"},
      {"name": "Grand Seiko"},
      {"name": "Greubel Forsey"},
      {"name": "H. Moser & Cie"},
      {"name": "Hermès"},
      {"name": "HYT"},
      {"name": "Jacob & Co."},
      {"name": "Louis Moinet"},
      {"name": "Mido"},
      {"name": "Mühle-Glashütte"},
      {"name": "Nomos Glashütte"},
      {"name": "Officine Panerai"},
      {"name": "Piaget"},
      {"name": "Rado"},
      {"name": "Richard Mille"},
      {"name": "Roger Dubuis"},
      {"name": "Seiko"},
      {"name": "Sinn"},
      {"name": "Junghans"},
      {"name": "Georg Jensen"},
      {"name": "Komono"},
      {"name": "Giuliano Mazzuoli"},
      {"name": "Bulova"},
      {"name": "Timex"},
      {"name": "Tissot"},
      {"name": "Shinola"},
      {"name": "Thomas Earnshaw"},
      {"name": "Skagen"},
      {"name": "Alpina"},
      {"name": "Festina"},
      {"name": "MeisterSinger"},
      {"name": "Philippe Dufour"},
      {"name": "Nixon"},
      {"name" : "George Daniels"},
      {"name" : "Wenger"},
      {"name": "Vortic Watches"},
      {"name": "Autodromo"},
      {"name": "Devon"},
      {"name": "Martenero"},
      {"name": "Weiss Watch Company"},
      {"name": "RGM"},
      {"name": "Brew Watch Co."},
      {"name": "Kobold Watches"},
      {"name": "Oak & Oscar"},
      {"name": "Lüm-Tec"},
      {"name": "Keaton Myrick"},
      {"name": "DuFrane"},
      {"name": "Throne"},
      {"name": "Detroit Watch Company"},
      {"name": "Seagull"},
      {"name": "Peacock"},
      {"name": "Atelier Wen"},
      {"name": "CIGA Design"},
      {"name": "Memorigin"},
      {"name": "Longio Watches"},
      {"name" : "Glashütte"},
      {"name" : "Longines"}

   ]
   }
}
