import DataStorageFile from '../utils/data-storage.js';
const dataStorage = new DataStorageFile();

export default class WalletManager{

    async handleAccountsChanged (accounts)  {
        if (accounts.length === 0) {
        } else {
            const account = accounts[0];
            console.log('Conta conectada:', account);
            dataStorage.setData("walletId", account)

        }
    };

    async initWallet() {
        if (window.ethereum) {
            window.ethereum.on('accountsChanged', this.handleAccountsChanged);

            try {
                const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
                const account = accounts[0];
                console.log(account)
                console.log(accounts)

                const chainId = process.env.REACT_APP_CHAIN_ID.toString();
                console.log(chainId)
                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: chainId }],
                    });
                } catch (switchError) {
                    if (switchError.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [{
                                    chainId: chainId,
                                }],
                            });
                        } catch (addError) {
                            console.error(addError);
                        }
                    } else {
                        console.error(switchError);
                    }
                }
                console.log('connected');
                return account;
            } catch (error) {
                console.error(error);
            }
        } else {
            console.error('Web3 not injected by the browser.');
        }


    }


    async connectWallet() {
        try {
            let walletId = await this.initWallet()
            if(walletId){
                dataStorage.setData("walletId", walletId)
                console.log('connected wallet id  : ' + dataStorage.getData('walletId'))
                return true
            }

        } catch (e) {
            console.error("Error connecting wallet:", e);
        }
    }

}