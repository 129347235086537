import React from 'react';
import './collection.css';
import DataStorageFile from '../../utils/data-storage.js';
import CollectionService from "./services/collection-services";
import { withTranslation } from 'react-i18next';
import {Link    } from "react-router-dom";
import AuthService from '../../services/auth-services.js';
import loadingGif from "../../images/loading-gif.gif";
import {Web3} from "web3";
import {myABI} from "../../utils/helper";
import ContractInteractor from "../../services/contract-interactor";

const dataStorage = new DataStorageFile();

class ChangeNameCollectionComponent extends React.Component {


  constructor() {
    super();
    this.contractInteractor = new ContractInteractor()
    this.authService = new AuthService();
    this.collectionService = new CollectionService()
    this.state = {
      jsonCollection: [],
      loadedCollection: String,
      loading: false,
    };
  }

  componentDidMount() {

  }

  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  async componentDidUpdate(prevProps) {

  }

  checkNameEligibility = async(name) => {
    const length = name.length;
    if (length >= 6 && length <= 24) {
      const isValid = /^[^0x][^\s]*$/.test(name);
      if (!isValid) {
        return false
      }
    }
    const nameAvailabilityResponse = await this.collectionService.checkName(name);
    return nameAvailabilityResponse.result;
  }
  async preCheck(){
    if (!dataStorage.getData("walletId")) {
      return false
    }
    this.setState({ loading: true });
    try {
      const res = await this.authService.checkAvalability();
      if (!res.data.status) {
        this.setState({ loading: false, response: false, showForm: true });
        return false
      } else {
        this.setState({response: true})
      }
    } catch(e){
      this.setState({ loading: false, response: false, showForm: true });
      return false
    }
    return true
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    const isEligible = await this.checkNameEligibility(event.target.elements.name.value)
    if(!isEligible){
        this.setState({loading: false});
        this.setState({nameInUse: true});
        return
    }
     if(!await this.preCheck()){
       return
       }
      let transactionObject = await this.contractInteractor.payForCollectionName(event.target.elements.name.value)
      if(!transactionObject){
        return this.setState({ loading: false, response: false, showForm: true });
      }
      const web4 = new Web3(window.ethereum);
      await web4.eth.sendTransaction(transactionObject)
          .on('transactionHash', hash => {
            this.setState({loading: false, response: true, showForm: false});
            console.log('Transaction hash:', hash);
          })
          .on('receipt', receipt => {
            this.setState({loading: false, response: true, showForm: false});
            console.log('Transaction receipt:', receipt);
          })
          .on('error', error => {
            console.error('Error sending transaction:', error);
          });

  }

  render() {

    const {t} = this.props;

    return (
        <div className="collection">
          <div className="sidebar">
            <Link to="/collection">
              <button className="collection-buttons">
                {t('back.to.collection')}
              </button>
            </Link>
            <div className='mint-explanation-banner'>

              <p style={{whiteSpace: 'pre-wrap'}}>
                {t('function.change.name.explanation')}
              </p>
            </div>
          </div>
          <div className='mint-client-wrapper'>

            {this.state.loading ?
                (
                    <div className="loading-container">
                      <img src={loadingGif} className="loading-gif" alt=""/>
                    </div>) :
                this.state.response && !this.state.showForm ?
                    <div className="text-success-mint">
                      <div>{t('change.name.success')} </div>
                    </div> :
                    (
                        <div className="collection-name-form">

                          <form onSubmit={this.handleSubmit} className="form">


                            <input
                                type="text"
                                placeholder={t('form.new.name')}
                                id="name"
                                name="name"
                                onChange={(event) => this.setState({name: event.target.value})}
                                className="form-input"
                                maxLength="24"
                                minLength="6"
                                pattern="^\S*$"
                            />

                            <span> {t('form.new.name.explanation')}</span>


                            <button type="submit">{t('form.button.set.name')}</button>

                          </form>
                        </div>
                    )}
          </div>
        </div>
    )
  }
}


export default withTranslation()(ChangeNameCollectionComponent);