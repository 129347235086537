import React, { useState } from 'react';
import './apintegrationhomecomponent.css';
import IntegrationAPI from './services/api-integration-services.js';
import {Navigate} from "react-router-dom";



class ApiIntegrationHomeComponent extends React.Component {
  
  
  constructor() {
    super();
    this.state = {
      tokenList : [],
      createdNewTokenBanner: false
    };
  }
  
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
      this.getTokenList()
  }


  getTokenList = async () => {
    const integrationAPIServices = new IntegrationAPI()
    const res = await integrationAPIServices.list()
    if(res){
      this.setState({tokenList: res.data.tokens})
      this.getTokenList()
    }
  }


  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  handleSubmit = async () => {
    const integrationAPIServices = new IntegrationAPI()
    const res = await integrationAPIServices.create()
    if(res){
      this.setState({createdNewTokenBanner: true})
      this.setState({createdToken: res.data.token})
      this.setState({createdTokenMessage: res.data.message})
    }}

  render() {
    if (!localStorage.getItem('token')) {
      return <Navigate to="/login" replace={true} />
    }
    return(

      <div className="integration-home">
      <div className='title'>API Integrations</div>

        <div>
          <button className="dash-buttons" onClick={this.handleSubmit}>New</button>
        </div>

        {this.state.createdNewTokenBanner && (
            <div className="created-token-banner">
              <div>{this.state.createdTokenMessage}</div>
              <div>{this.state.createdToken}</div>
            </div>
        )}

      <div className='integration-home-wrapper'>



        <table>
            <thead>
            <tr>
              <th>Token ID</th>
              <th>Creation Date</th>
              <th>Status</th>
            </tr>
            </thead>
            <tbody>
            {this.state.tokenList.map((entry, index) => (
                <tr key={index}>
                  <td>{entry._id}</td>
                  <td>{new Date(entry.date).toLocaleString()}</td>
                  <td>{entry.enabled ? "Enabled" : "Disabled"}</td>
                </tr>
            ))}
            </tbody>
          </table>
      </div>
      </div>
      );
    }
  }
  
  
  export default ApiIntegrationHomeComponent;
  