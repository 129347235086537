import React from 'react';
import './logincomponent.css';
import {Navigate} from 'react-router-dom';
import LoginService from './services/login-service.js';



class LoginComponent extends React.Component {


  constructor() {
    super();

    this.state = {
      redirectToReferrer: false
    };
  }
  
  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }
  
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    // const navigate = useNavigate();
    // navigate("/partnership");
    const loginService = new LoginService()
    loginService.start(this.state)
        .then(r => {
          this.setState({redirectToReferrer:true})
        });
  }

  render() {
    const redirectToReferrer = this.state.redirectToReferrer;
    if (redirectToReferrer) {
        // redirect("/partnership");
      return <Navigate to="/dashboard" replace={true} />

    }
    return(
      <div className="login">
      <div className='title'>Login</div>
      <div className='login-wrapper'>
      <div className='box'>
        <div className="section-form">
            <form onSubmit={this.handleSubmit} className="form">

              <div>
                <input
                    type="text"
                    placeholder="E-mail"
                    id="email"
                    name="email"
                    required
                    value={this.state.email}
                    onChange={(event) => this.setState({email: event.target.value})}
                    className="form-input"
                />
              </div>


              <div>
                <input
                    type="text"
                    placeholder="Password"
                    id="password"
                    name="password"
                    value={this.state.password}
                    onChange={(event) => this.setState({password: event.target.value})}
                    className="form-input"
                />
              </div>

              <button type="submit">Submit</button>
            </form>
      </div>
      </div>
      </div>
      </div>
      );
    }
  }
  
  
  export default LoginComponent;
  