import AuthService from './auth-services.js';
import {Web3} from "web3";
import {myABI} from "../utils/helper";
import DataStorageFile from '../utils/data-storage.js';

const dataStorage = new DataStorageFile();

export default class ContractInteractor{

    constructor() {
        this.authService = new AuthService();
        const httpProvider = new Web3.providers.HttpProvider(process.env.REACT_APP_WEB3PROVIDER);
        this.web3 = new Web3(httpProvider);
        let contractABI = myABI();
        this.contract = new this.web3.eth.Contract(contractABI,process.env.REACT_APP_MASTER_CONTRACT);
    }



    async checkAvailability() {
        try {
            const res = await this.authService.checkAvalability();
            return res.data.status;
        } catch(e){
            return false
        }
    }


    async payForCollectionName(name){
        try {
            if (!await this.checkAvailability()) {
                return false
            }
            const mintingFee = await this.contract.methods.getMintingFee().call();
            return {
                to: process.env.REACT_APP_MASTER_CONTRACT,
                gasLimit: 3141592,
                value: this.web3.utils.toHex(mintingFee),
                data: this.contract.methods.payForCollectionName(name).encodeABI(),
                from: dataStorage.getData("walletId")
            }
        } catch(e){
            return false
        }
    }

    async payForMinting(uri){
        try {
            if (!await this.checkAvailability()) {
                return false
            }
            console.log('try minting fee')
            console.log(this.contract)
            const mintingFee = await this.contract.methods.getMintingFee().call();
            console.log('minting fee '+mintingFee)
            // const gas_estimate = await web3.eth.estimateGas(tx);
            // tx.gas = gas_estimate;
            return {
                to: process.env.REACT_APP_MASTER_CONTRACT,
                gasLimit: 3141592,
                value: this.web3.utils.toHex(mintingFee),
                data: this.contract.methods.payForMinting(uri).encodeABI(),
                from: dataStorage.getData("walletId")
            }
        } catch(e){
            return false
        }
    }









}