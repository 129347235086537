import axios from 'axios';
import DataStorageFile from '../../../utils/data-storage.js';
const dataStorage = new DataStorageFile();
export default class LoginService{
    constructor() {
        axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
        axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    }
    async start(body){
        try {
            const url = process.env.REACT_APP_API_URL+'/login'; // URL da API
            const data = {
                'email': body.email,
                'password' : body.password
            };
            const response = await axios.post(url, data);
            if (response.status === 200) {
                localStorage.setItem('token', response.data.token);
                dataStorage.setData("email", body.email)
                localStorage.setItem("balance", response.data.balance)
                return response.data
            }
        } catch (error) {
            console.error(error);
        }

    }

    async balanceRecord(){
        try {
            const url = process.env.REACT_APP_API_URL+'/balance-record'; // URL da API

            // Replace with your actual JWT token
            const headers = {
                Authorization: localStorage.getItem('token')
            };
            const response = await axios.get(url, {headers});
            return response
        } catch (error) {
            console.log(error)
            return error
        }

    }




}