import {Link,HashRouter} from "react-router-dom";
import React from "react";
import img1 from '../images/twitter_img.png';

function Bottom(props) {
    return (
        <div className="classic-footer">
            <HashRouter >

            <div className="footer-container">
                <Link to="https://twitter.com/thewatchdex">
                    <div className={"footer-social"}>
                        <img src={img1} style={{ marginRight: '20px', width: '35px', height: '35px'}} alt="" />
                    </div>
                </Link>
        <p>© 2023 - 2024 Watchdex </p>

        <ul>
            {/*<Link to="/privacy-policy">Privacy Policy</Link>*/}
        </ul>

      </div>
            </HashRouter>

        </div>
    );
  }
  

export default Bottom;
